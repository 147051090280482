/*********************
Block Styles
*********************/

/* Blog Posts Block */
.recent-posts {
	article {
		height: 100%;
		background: $white;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
		transition: all .3s ease;
		
		&:hover {
			transform: translateY(1px);
			box-shadow: 0px 1px 3px rgba(0, 0, 0, .12);
		}
	}
	
	.featured-image {
		width: 100%;
		height: auto;
		margin-bottom: 0;
	}
	
	.post-tile-body {
		padding: 2rem 8% 2.5rem 8%;
	}
	
	a {
		h3 {
			color: $blue !important;
			margin-bottom: 1.5rem;
		}
	}
	
	@include breakpoint(small only) {
	
	}
	
	@include breakpoint(medium only) {
		
	}
	
	@include breakpoint(large) {
		h3 {
			font-size: rem-calc(24);
		}
	}
	
	.minutes {
		justify-content: flex-start;
		
		.minutes-icon {
			width: 1rem;
			height: 1rem;
			margin-right: 0.625rem;
		}
		
		.minutes-value {
			color: $blue;
			line-height: 1;
			font: {
				weight: 500;
				size: 1rem;
			}
		}
	}
}

/* Blog Cards */
.blog-card {
	height: 100%;

	.card-body {
		padding: 2rem 8% 2.5rem 8%;
	}

	@include breakpoint(medium) {
		.card-image {
			width: 100%;
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
	}
	
	@include breakpoint(medium only) {
		.card-image {
			height: rem-calc(230);
		}
	}
	
	@include breakpoint(large) {
		.card-image {
			height: rem-calc(210);
		}
	}
}

.recent-posts, .recent-resources  {
	.section-heading h2 {
		margin-bottom: rem-calc(75);
	}
	.post-type-archive-link {
		padding-top: rem-calc(75);
	}
}

/* Heading Block */

.heading-block {
	.cell.text-center {
		h2:after {
			left: 50% !important;
			transform: translateX(-50%);
		}
	}
}

/* Text and Media */
.text-media {
	.tm-media-column-wrapper {
		img, svg {
			max-width: 100%;
			height: auto;
		}

		
	}
	
	.button {
		margin-top: rem-calc(32);
	}
	@include breakpoint(small only) {
		.small-order-2 > .tm-text-column-wrapper {
			padding-top: rem-calc(32);
		}
		
		.small-order-1 > .tm-text-column-wrapper {
			padding-bottom: rem-calc(32);
		}
	}

	@include breakpoint(medium) {
		.medium-6 {
			&.medium-order-1 {
				.tm-media-column-wrapper {
					padding-right: 10%;
				}
			}
			&.medium-order-2 {
				.tm-media-column-wrapper {
					padding-left: 10%;
				}
			}
		}
	}
	
}

.tm-text-column-wrapper {
	.button {
		margin-top: 1rem;
	}
}

/* Text and Form */
.text-form {
	.large-order-1 {
		.tm-text-column-wrapper {
			padding-right: 15%;
		}
	}
	.large-order-2 {
		.tm-text-column-wrapper {
			padding-left: 15%;
		}
	}

	input[type=submit].button {
		&:hover {
			background: $blue;
		}
	}
}

/* Text two columns */

.tct-block {
	
	ul {
		li:not(:first-child) {
			padding-top: rem-calc(8);
		}
	}
	.button {
		margin-top: rem-calc(32);
	}
	
	@include breakpoint(small only) {
		.grid-x {
			.cell:first-child {
				margin-bottom: 3rem;
			}
		}
	}
	
	@include breakpoint(medium) {
		.grid-x {
			.cell:first-child {
				.tct-cell-inner {
					padding-right: 5%;
				}
			}
			
			.cell:last-child {
				.tct-cell-inner {
					padding-left: 5%;
				}
			}
		}
	}
}

/* Home Hero */
.home-hero {
	position: relative;
	z-index: 0;
	
	
	.home-hero-bg {
		position: absolute;
		z-index: -1;
		height: 100%;
		width: 100%;
		background: {
			size: cover;
			position: center center;
			repeat: no-repeat;
			//attachment: fixed;
		}
		
	}
	
	.home-hero-inner {
		background: transparent linear-gradient(112deg, rgba($teal, .87) 0%, rgba(20, 65, 88, .87) 100%) 0% 0% no-repeat padding-box;

		&.has-nb {
			@include breakpoint(small only) {
				padding: {
					top: rem-calc(250);
					bottom: rem-calc(180);
				}
			}
			@include breakpoint(medium only) {
				padding: {
					top: rem-calc(200);
					bottom: rem-calc(175);
				}
			}
			
			@include breakpoint(large) {
				padding: {
					top: rem-calc(200);
					bottom: rem-calc(220);
				}
			}
		}
		&:not(.has-nb) {
			@include breakpoint(small only) {
				padding: {
					top: rem-calc(120);
					bottom: rem-calc(150);
				}
			}
			@include breakpoint(medium only) {
				padding: {
					top: rem-calc(150);
					bottom: rem-calc(175);
				}
			}
			
			@include breakpoint(large) {
				padding: {
					top: rem-calc(200);
					bottom: rem-calc(220);
				}
			}
		}
		
		
	}
	
	.home-hero-content-wrapper {
		h1 {
			color: $white;
			//font-weight: 500;
			font-weight: 700;
			
			/*
			> span {
				opacity: 0;
				transform: translateX(-101%);
			}
			*/
			
			.heading-label {
				display: inline-block;
				background: rgba($dark-blue, .68);
				text-transform: uppercase;
				font-weight: 500;
				line-height: 1;
				letter-spacing: rem-calc(3.5);
			}
			
			.kiwi-text {
				color: $kiwi;
				font-weight: 700;
				text-transform: uppercase;
			}
			
			.small-underline {
				font-weight: 400;
				font-style: italic;
				display: inline-block;
				position: relative;
				letter-spacing: rem-calc(2.8);
				
				&:after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: rem-calc(3);
					background-color: $dark-blue;
				}
			}
			
			@include breakpoint(small only) {
				text-align: center;
				h1 {
					font-size: rem-calc(18);
				}
				.heading-label-wrapper {
					display: block;
					margin-bottom: rem-calc(8);
				}
				.heading-label {
					display: inline-block;
					font-size: rem-calc(14);
					padding: rem-calc(8) rem-calc(10);
				}
				
				.kiwi-text {
					display: block;
					font-size: rem-calc(18);
					margin-top: rem-calc(8);
				}
				.small-underline {
					font-size: rem-calc(18);
				}
			}
			
			@include breakpoint(medium only) {
				
				h1 {
					font-size: rem-calc(48);
				}
				
				.heading-label {
					font-size: rem-calc(21);
				}
				
				.regular-text, .small-underline {
					font-size: rem-calc(48);
				}
				
				.kiwi-text {
					font-size: rem-calc(48);
				}
			}
			
			@include breakpoint(medium) {
				.heading-label {
					padding: rem-calc(12) rem-calc(24) rem-calc(10) rem-calc(24);
					/*
					opacity: 0;
					transform: translateY(-400%);
					*/
				}
				/*
				.regular-text, .small-underline {
					opacity: 0;
				}
				*/
				.heading-label-wrapper {
					display: block;
				}
				.kiwi-text {
					display: block;
					/*
					opacity: 0;
					transform: rotateX(90deg);
					*/
					
				}
				.small-underline {
					font-size: 2rem;
				}
			}
			
			
			@include breakpoint(large) {
				
				//font-size: rem-calc(84);
				font-size: 4rem;
				
				.heading-label {
					//font-size: rem-calc(20);
					font-size: rem-calc(16);
				}
				.small-underline {
					//font-size: rem-calc(49);
					font-size: 2.2rem;
					transform: translateY(-10px);
				}
				.kiwi-text {
					//font-size: rem-calc(60);
					font-size: 3.5rem;
				}
			}
		}

	}

	.hh-text-wrapper {
		position: relative;
		padding-top: rem-calc(40);
		color: $white;
		font-size: rem-calc(20);

		&:before {
			content: '';
			position: absolute;
			top: 0;
			height: rem-calc(3);
			background: $light-gray;
			@include breakpoint(small only) {
				width: rem-calc(200);
				left: 50%;
				transform: translateX(-50%);
			}
			@include breakpoint(medium only) {
				width: rem-calc(220);
			}
			@include breakpoint(medium) {
				left: 0;
			}
			@include breakpoint(large) {
				width: rem-calc(300);
			}
		}

		@include breakpoint(small only) {
			text-align: center;
			font-size: rem-calc(16);
			padding-top: rem-calc(30);
			margin-top: rem-calc(30);
		}

		@include breakpoint(medium only) {
			padding-right: 25%;
		}

		@include breakpoint(medium) {
			padding-top: rem-calc(40);
		}

		@include breakpoint(large) {
			padding-right: 33%;
		}

	}

	.hh-button-wrapper {
		padding-top: rem-calc(50);
		@include breakpoint(small only) {
			text-align: center;
		}
	}
	
	
	.hh-logos-wrapper {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 0;
		background: {
			image: url(../images/svg/graphic-hero-white-curve-icons.svg);
			position: center bottom;
			repeat: no-repeat;
		}
		
		&:after {
			content: '';
			position: absolute;
			bottom: -2px;
			left: 0;
			width: 100%;
			height: 10%;
			background: $white;
			z-index: -1;
		}
		
		
		
		@include breakpoint(small only) {
			background-size: 1200px 50%;
			
			.hh-logo {
				padding: {
					left: rem-calc(8);
					right: rem-calc(8);
				}
			}
			
			
			.grid-margin-x .cell {
				margin-left: 0 !important;
				margin-right: 0 !important;
				width: 33.3333%
			}
		}
		
		@include breakpoint(medium only) {
			background-size: 2000px 50%;
			.hh-logo {
				max-width: rem-calc(120);
			}
		}
		
		@include breakpoint(large) {
			.hh-logo {
				max-width: rem-calc(160);
			}
			
		}
		
		@include breakpoint(large) {
			background-size: 3000px 50%;
		}
		
		@include breakpoint(1850px) {
			padding-top: 3rem;
			background-size: 4000px 50%;
		}
		
		
		@include breakpoint(2500px) {
			padding-top: 5rem;
			background-size: 6000px 50%;
		}
		
	}
}

/* Job Benefits */

.job-benefits-wrapper {
	background: $light-gray;
	position: relative;
	padding-bottom: rem-calc(60);
	
	.jb-heading {
		display: inline-block;
		
		background: transparent linear-gradient(95deg, rgba(0, 112, 105, 1) 0%, rgba(20, 65, 88, 1) 100%) 0% 0% no-repeat padding-box;
		transform: translateY(-50%);
		text-align: center;
		padding: rem-calc(16) rem-calc(42);
		
		h2 {
			color: $white;
			text-transform: uppercase;
			margin: 0;
			letter-spacing: rem-calc(1.88);
			
			@include breakpoint(small only) {
				font-size: rem-calc(18);
			}
			
			@include breakpoint(medium only) {
				font-size: rem-calc(21);
			}
			
			@include breakpoint(large) {
				font-size: rem-calc(25);
			}
		}
	}
}

.benefits {
	align-items: center;
	@include breakpoint(small only) {
		justify-content: center;
	}
	.cell {
		.benefit {
			color: $blue;
		}
		/*
		&:nth-child(odd) {
			.benefit {
				color: $blue;
			}
		}
		
		&:nth-child(even) {
			.benefit {
				color: $teal;
			}
		}
		*/
		@include breakpoint(small only) {
			&:not(:first-child) {
				.benefit {
					border-top: 2px solid $white;
				}
			}
		}
		
		@include breakpoint(medium only) {
			&:not(:nth-child(-n+2)) {
				.benefit {
					border-top: 2px solid $white;
				}
			}
		}
		
		@include breakpoint(large) {
			&:not(:nth-child(-n+3)) {
				.benefit {
					border-top: 2px solid $white;
				}
			}
		}
	}
	
	.benefit {
		padding: {
			top: rem-calc(24);
			bottom: rem-calc(24);
		}
		
		.benefit-icon {
			width: rem-calc(45);
			margin-right: rem-calc(12);

			svg path {
				fill: $blue;
			}
		}
		
		.benefit-text {
			font-weight: 600;
			@include breakpoint(medium) {
				font-size: rem-calc(21);
			}
		}
	}
	
}

/* Tech Sectors */

$ts-height-large: rem-calc(700);
$ts-height-medium: rem-calc(600);
$ts-height-small: rem-calc(200);

.tech-sectors-wrapper {
	position: relative;
	z-index: 0;
	@include breakpoint(small only) {
		min-height: $ts-height-small;
	}
	@include breakpoint(medium only) {
		min-height: $ts-height-medium;
	}
	@include breakpoint(large) {
		min-height: $ts-height-large;
	}
}

.tech-sectors {
	.tech-sectors-image {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		/* push down 35% for parallax clearance */
		transform: translateY(35%);
		background: {
			size: cover;
			repeat: no-repeat;
			position: center center;
		}
		@include breakpoint(small only) {
			/*
			height: $ts-height-small;
			width: rem-calc(250);
			*/
			display: none;
		}
		@include breakpoint(medium only) {
			height: $ts-height-medium;
			width: rem-calc(500);
		}
		@include breakpoint(large) {
			height: $ts-height-large;
			width: rem-calc(800);
		}
	}
	.content-wrapper {
		background: -moz-linear-gradient(-122deg, rgba(0,112,105,0.91) 0%, rgba(20,65,88,0.91) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(-122deg, rgba(0,112,105,0.91) 0%, rgba(20,65,88,0.91) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(238deg, rgba(0,112,105,0.91) 0%, rgba(20,65,88,0.91) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8007069', endColorstr='#e8144158',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	}
	
	.content-inner {
		color: $white;
		background: {
			image: url(../images/svg/Tech-Sector-Pattern.svg);
			repeat: no-repeat;
			size: contain;
			position: right bottom;
		}
		
		@include breakpoint(small only) {
			padding: rem-calc(40) rem-calc(24);
		}
		
		@include breakpoint(medium only) {
			padding: rem-calc(64) 15%;
		}
		
		@include breakpoint(large) {
			padding: rem-calc(64) 21.175%;
		}
		/*
		@include breakpoint(65.625em) {
			
		}
		*/
		h2 {
			color: $white;
			line-height: 1.275;
			margin-bottom: 0;
		}
		
		ul {
			
			margin-bottom: 0;
			
			@include breakpoint(small only) {
				column-gap: rem-calc(20);
				-webkit-column-gap: rem-calc(20);
				-moz-column-gap: rem-calc(20);
			}
			@include breakpoint(medium) {
				column-gap: rem-calc(60);
				-webkit-column-gap: rem-calc(60);
				-moz-column-gap: rem-calc(60);
			}
			
			li {
				color: $white;
				
				
				span {
					position: relative;
					display: inline-block;
					
					
					&:before {
						content: '•';
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-60%);
						color: $kiwi;
						

						@include breakpoint(medium) {
							font-size: 3rem;
							line-height: rem-calc(29);
						}
					}
				}
				
				
				
				@include breakpoint(small only) {
					padding-top: rem-calc(20);
					font-size: rem-calc(14);
					
					span {
						padding-left: rem-calc(18);
					}
				}
				
				@include breakpoint(medium) {
					padding-top: rem-calc(45);
					font-size: rem-calc(23);
					
					span {
						padding-left: rem-calc(24);
					}
				}
			}
			
		}
	}
}

/* Accordion */

.accordion-block {
	.accordion-heading {
		h2 {
			color: $kiwi;
			margin-bottom: rem-calc(32);
		}
	}

	&.jobs {
		h2 {
			margin-bottom: rem-calc(45);
		}


	}
	
	.accordion {
		.accordion-item {
			border-bottom: 1px solid $border-gray;
			&:first-child {
				border-top: 1px solid $border-gray;
			}
			.accordion-title {
				
				font-weight: 600;
				&:hover, &:focus {
					color: $blue;
				}
				&:before {
					line-height: 1;
					margin-top: 0 !important;
					left: 0 !important;
					right: auto !important;
					transform: translateY(-50%);
					color: $kiwi;
				}
				
				@include breakpoint(small only) {
					font-size: rem-calc(18);
					padding: 1rem 1.25rem 1rem 2.5rem;
					&:before {
						font-size: rem-calc(36);
					}
				}
				
				@include breakpoint(medium) {
					font-size: rem-calc(28);
					&:before {
						font-size: rem-calc(49);
					}
				}
			}
		}
	}
	
	.accordion-content {

		ul, ol {
			margin-bottom: rem-calc(24);
			margin-top: rem-calc(5);
			li {
				&:not(:first-child) {
					margin-top: rem-calc(8);
				}
			}
		}

		.button {
			margin-top: rem-calc(40);
			margin-bottom: rem-calc(16);
		}
		@include breakpoint(small only) {
			padding: 0 1.25rem;
			
			table {
				tr {
					font-size: rem-calc(13);
					strong {
						font-size: rem-calc(12);
					}
					
					> td:first-child {
						padding-right: rem-calc(10);
					}
					> td:second-child {
						padding-left: rem-calc(10);
					}
					
				}
			}
		}
	}
}

/* Featured Resources CTA */
.featured-resources {
	
	@include breakpoint(medium down) {
		.grid-x > .cell:not(:first-child) {
			margin-top: rem-calc(80);
		}
	}
	
	.featured-item {
		transform: translateY(200px);
		opacity: 0;
		background: $dark-blue;
		color: $white;
		position: relative;
		padding: rem-calc(78) rem-calc(30) rem-calc(58) rem-calc(30);
		
		.featured-title {
			position: absolute;
			top: 0;
			left: 50%;
			height: rem-calc(49.62);
			
			background: $kiwi;
			
			
			@include breakpoint(small only) {
				width: 100%;
				top: rem-calc(-10);
				left: 0;
				padding-top: .5rem;
				padding-bottom: .5rem;
			}
			
			@include breakpoint(medium) {
				width: rem-calc(280.92);
				top: 0;
				left: 50%;
				height: rem-calc(49.62);
				transform: translate(-50%, -50%);
			}
			
			h2 {
				color: $white;
				margin-bottom: 0;
				font-weight: 600;
				font-size: rem-calc(18);
				text-transform: uppercase;
			}
			
		}
		
		.featured-text {
			position: relative;
			
			margin-top: rem-calc(30);
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				height: rem-calc(1);
				width: rem-calc(190);
				background: $kiwi;
				transform: translate(-50%, -50%);
			}
			
			@include breakpoint(small only) {
				padding: {
					top: rem-calc(20);
					bottom: rem-calc(12);
					left: rem-calc(24);
					right: rem-calc(24);
				}
			}
			
			@include breakpoint(medium) {
				padding: {
					top: rem-calc(20);
					bottom: rem-calc(12);
					left: rem-calc(60);
					right: rem-calc(60);
				}
			}
			
			@include breakpoint(large) {
				
			}
		}
		
		.button {
			margin-top: rem-calc(30);
		}
	}
}

/* Divider */

.divider-block {
	text-align: center;
	hr {
		width: 80%;
		border-bottom: rem-calc(3) solid rgba($gray, .25);
	}
}

/* Three column callout */

.callout-column {
	
	.cc-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: rem-calc(32);
		
		svg {
			transform: scale(.1);
			opacity: 0;
			g {
				fill: $blue !important;
			}
		}
	}
	.cc-heading {
		margin-bottom: rem-calc(24);
	}
	.cc-button {
		margin-top: rem-calc(50);
	}
	
	@include breakpoint(small only) {
		padding: {
			top: rem-calc(32);
			bottom: rem-calc(32);
		}
	}
	@include breakpoint(medium) {
		padding: {
			top: rem-calc(40);
			bottom: rem-calc(40);
		}
	}
}

@include breakpoint(small only) {
	.tcc-block .grid-x .cell:last-child .callout-column {
		padding-bottom: 0;
	}
}

@include breakpoint(medium only) {
	.tcc-block .grid-x .cell:not(:last-child) .callout-column {
		padding-bottom: rem-calc(40);
	}
}

/* Services Vertical Accordion */

.vertical-services-accordion {
	
	.services-accordion-inner {
		
		&.bg-gray {
			@include breakpoint(small only) {
				padding-top: rem-calc(45);
				padding-bottom: 0;

			}

			@include breakpoint(medium only) {
				padding-top: rem-calc(75);
				padding-bottom: rem-calc(80);

			}

			@include breakpoint(large) {
				padding-top: rem-calc(90);
				padding-bottom: rem-calc(100);

			}
		}
		
		
		.vsa-heading-grid {
			h2 {
				margin-bottom: rem-calc(45);
			}
		}
		@include breakpoint(small only) {
			.grid-container {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
			padding: {
				top: rem-calc(45);
				
			}
			
			.vsa-heading-grid {
				padding: {
					left: rem-calc(24);
					right: rem-calc(24);
				}
				
			}
		}
		@include breakpoint(medium only) {
			padding: {
				top: rem-calc(90);
				bottom: rem-calc(100);
			}
			
			.vsa-heading-grid {
				padding-bottom: rem-calc(45);
			}
		}
		
		
	}
	
	.overlay-blue {
		background-color: rgba($blue, .9);
	}
	.overlay-green {
		background-color: rgba($teal, .9);
	}
	
	.vsa-accordion-wrapper {
		@include breakpoint(large) {
			overflow: hidden;
		}
	}
	
	.vsa-accordion {
		@include breakpoint(large) {
			position: relative;
			z-index: 0;
			
		}
		
		/*
		@include breakpoint(medium only) {
			.vsa-accordion-item:not(:first-child) {
				margin-top: rem-calc(40);
			}
		}
		*/
	}
	
	.vsa-accordion-item {
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		
		
		@include breakpoint(medium down) {
			
			/*
			&:not(.is-active) .vsa-accordion-content {
				transform: scaleY(0);
				opacity: 0;
				visibility: hidden;
				
			}
			&.is-active .vsa-accordion-content {
				transform: scaleY(1);
				opacity: 1;
				visibility: visible;
				
			}
			
			
			.item-inner-grid {
				&:not(.is-active) {
					.content-column {
						display: none;
					}
				}
				
				&.is-active {
					.content-column {
						display: block;
					}
				}
			}
			
			.vsa-toggle {
				text-transform: uppercase;
				cursor: pointer;
				display: inline-block;
				padding-bottom: .25rem;
				width: auto;
				font-size: rem-calc(14);
				margin: rem-calc(32) auto 0 auto;
				color: $white;
				border-bottom: 1px solid $white;
			}
			
			background-image: none !important;
			
			.title-column {
				
				padding: {
					left: 0 !important;
					right: 0 !important;
				}
				background: {
					size: cover;
					position: center center;
					repeat: no-repeat;
				}
			}
			
			.vsa-accordion-item-inner.overlay-blue {
				background-color: $blue;
				.vsa-accordion-title {
					background: rgba($blue, .9);
				}
			}
			
			.vsa-accordion-item-inner.overlay-green {
				background-color: $teal;
				.vsa-accordion-title {
					background: rgba($teal, .9);
				}
			}
			
			.content-column {
				
				transition: opacity .3s ease;
				transform-origin: top;
				height: 0;
				opacity: 0;
				overflow: hidden;
				
				.vsa-accordion-content {
					
					
					
					padding-bottom: rem-calc(70);
					padding-top: rem-calc(70);
				}
			}
			*/
		}
		@include breakpoint(large) {
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			transition: transform .7s ease-in-out;
			background: {
				position: center center;
				size: cover;
				repeat: no-repeat;

			}
			
			.vsa-toggle {
				display: none;
			}
			
			.title-column {
				background-image: none !important;
			}

			&.item-1 {
				transform: translateX(0);
				z-index: 1;
			
			}
			&.item-2 {
				transform: translateX(25%);
				z-index: 2;
				&.open {
					transform: translateX(calc(0 + 75px));
				}
				&.slide-left {
					transform: translateX(calc(0 + 75px));
				}
				&.slide-right {
					transform: translateX(calc(100% - 225px))
				}
			}
			&.item-3 {
				transform: translateX(50%);
				z-index: 3;
				&.open {
					transform: translateX(calc(0 + 150px));
				}
				&.slide-left {
					transform: translateX(calc(0 + 150px));
				}
				&.slide-right {
					transform: translateX(calc(100% - 150px))
				}
				
			}
			&.item-4 {
				transform: translateX(75%);
				z-index: 4;
				&.open {
					transform: translateX(calc(0 + 225px));
				}
				&.slide-right {
					transform: translateX(calc(100% - 75px))
				}
			}
			
			&.closed {
				.item-inner-grid {
					opacity: 0;
				}
			}
			svg g {
				transition: opacity .7s ease-in-out;
			}
			&.open {
				.vsa-icon svg g {
					opacity: 1;
				}
			}
			
		}
		
	}
	
	.vsa-accordion-item-inner {
		color: $white;
		@include breakpoint(small only) {
			padding-bottom: rem-calc(70);
			.vsa-accordion-title {
				padding: {
					top: rem-calc(50);
					bottom: rem-calc(60);
				}
			}
		}
		@include breakpoint(medium only) {
			padding-bottom: rem-calc(70);
			.vsa-accordion-title {
				padding: {
					top: rem-calc(60);
					bottom: rem-calc(70);
				}
			}
			
		}
		@include breakpoint(large) {
			padding: {
				top: rem-calc(126);
				bottom: rem-calc(126);
			}
		}
		
	}
	
	.item-inner-grid {
		@include breakpoint(medium down) {
			
			> .cell {
				padding-left: rem-calc(15);
				padding-right: rem-calc(15);
			}
		}
		@include breakpoint(large) {
			height: inherit;
			opacity: 1;
			transition: opacity .6s ease-in-out;
		}
		
	}
	
	.vsa-accordion-title {
		.vsa-title {
			padding-bottom: rem-calc(12);
			margin-bottom: rem-calc(12);
			position: relative;
			h3 {
				color: $kiwi;
				text-transform: uppercase;
				margin: 0;
			}
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				width: rem-calc(104);
				height: 1px;
				background-color: $kiwi;
				transform: translateX(-50%);
			}
			
		}
		@include breakpoint(medium) {
			//padding-left: rem-calc(30);
			.vsa-icon {
				height: rem-calc(65);
			}
		}
		@include breakpoint(large) {
			.vsa-title h3 {
				font-size: rem-calc(18);
			}
		}
	}
	
	.vsa-heading {
		span {
			display: block;
			color: $white;
			text-transform: uppercase;
			line-height: 125%;
		}
		
		.vsa-heading-big {
			font-weight: 700;
			font-size: rem-calc(34);
		}
		
		.vsa-heading-small {
			font-weight: 500;
			font-size: rem-calc(22);
		}
	}
	
	.vsa-accordion-content {
		color: $white;
		@include breakpoint(small only) {
			padding: {
				left: rem-calc(24);
				right: rem-calc(24);
				//top: rem-calc(40);
			}
			
			.button-wrapper {
				text-align: center;
			}
		}
		@include breakpoint(medium only) {
			padding-right: rem-calc(30);
			padding-left: rem-calc(30);
		}
		@include breakpoint(large) {
			width: calc(100% - 225px);
			padding-right: rem-calc(50);
			padding-left: rem-calc(5);
		}
		h3 {
			color: $white;
			margin-bottom: rem-calc(24);
		}
		
		.button {
			margin-top: rem-calc(24);
		}
	}
}

/* CTA - Quick Service Cards */
.qsc {
	
	.qsc-inner {
		position: relative;
		padding: {
			bottom: rem-calc(80);
			top: rem-calc(90);
		}
		
		.short-heading-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			transform: translateY(-50%);
		}
		
	}
	.short-heading-wrapper {
		display: inline-block;
		background: transparent linear-gradient(95deg, rgba(0, 112, 105, 1) 0%, rgba(20, 65, 88, 1) 100%) 0% 0% no-repeat padding-box;
		text-align: center;
		padding: rem-calc(16) rem-calc(42);
		
		
		span {
			display: block;
			line-height: 1.25;
			color: $white;
			text-transform: uppercase;
			letter-spacing: rem-calc(1.88);
			font-weight: 600;
			
			@include breakpoint(small only) {
				font-size: rem-calc(18);
			}

			@include breakpoint(medium only) {
				font-size: rem-calc(21);
			}

			@include breakpoint(large) {
				font-size: rem-calc(25);
			}
		}
		
		
	}
	
	.qsc-heading {
		h2 {
			margin-bottom: rem-calc(15);
		}
		p {
			color: $blue;
			@include breakpoint(small only) {
				font-size: rem-calc(18);
			}

			@include breakpoint(medium only) {
				font-size: rem-calc(25);
			}

			@include breakpoint(large) {
				font-size: rem-calc(28);
			}
		}
	}
	
	.qsc-cards {
		margin-top: rem-calc(24);
		.qsc-card {
			background: $white;
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
			
			.card-icon {
				margin-bottom: rem-calc(32);
				svg {
					max-height: 100%;
					width: auto;
				}
				
			}
			
			h3 {
				margin-bottom: rem-calc(22);
			}
			
			.button {
				margin-top: rem-calc(15);
			}
			
			@include breakpoint(small only) {
				padding: rem-calc(45) rem-calc(32) rem-calc(62) rem-calc(32);
				
				.card-icon {
					height: rem-calc(153);
				}
			}
			
			@include breakpoint(medium only) {
				padding: rem-calc(45) rem-calc(32) rem-calc(62) rem-calc(32);
				
				.card-icon {
					height: rem-calc(153);
				}
			}
			
			@include breakpoint(large) {
				padding: rem-calc(45) rem-calc(32) rem-calc(62) rem-calc(32);
				
				.card-icon {
					height: rem-calc(153);
				}
			}
		}
		
	}
}



/* Testimonial */

.testimonial-inner {
	position: relative;
	z-index: 0;

	.testimonial-quote-graphic {
		position: absolute;
		z-index: -1;

		svg {
			width: 100%;
			height: auto;
			max-width: 100%;
		}

		@include breakpoint(small only) {
			top: 4.5rem;
			left: 50%;
			transform: translateX(-50%);
			height: rem-calc(246.41 * .65);
			width: rem-calc(313.21 * .65);
		}
		
		@include breakpoint(medium only) {
			top: 175px;
			left: -70px;
			height: rem-calc(246.41 * .85);
			width: rem-calc(313.21 * .85);
		}
		@include breakpoint(large) {
			top: 105px;
			left: -70px;
			height: rem-calc(246.41);
			width: rem-calc(313.21);
		}
	}
	/*
	&:before {
		content: '';
		position: absolute;
		
		
		
		z-index: -1;
		background: {
			image: url(../images/svg/graphic-homepage-quotation-mark-grey.svg);
			size: contain;
			position: center center;
			repeat: no-repeat;
		}
		
		@include breakpoint(small only) {
			top: 4.5rem;
			left: 50%;
			transform: translateX(-50%);
			height: rem-calc(246.41 * .65);
			width: rem-calc(313.21 * .65);
		}
		
		@include breakpoint(medium only) {
			top: 175px;
			left: -70px;
			height: rem-calc(246.41 * .85);
			width: rem-calc(313.21 * .85);
		}
		@include breakpoint(large) {
			top: 105px;
			left: -70px;
			height: rem-calc(246.41);
			width: rem-calc(313.21);
		}
	}

	*/



	h2 {
		color: $kiwi;
		@include breakpoint(small only) {
			margin-bottom: rem-calc(30);
			margin-top: rem-calc(40);
		}
		@include breakpoint(medium only) {
			margin-bottom: rem-calc(40);
			margin-top: rem-calc(60);
		}
		@include breakpoint(large) {
			margin-bottom: rem-calc(58);
			margin-top: rem-calc(80);
		}
		
	}
	.quote-wrapper {
		color: $gray;
		line-height: 1.75;
		margin-bottom: rem-calc(50);
		font: {
			style: italic;
			weight: 400;
		}
		
		strong {
			font-weight: 600;
		}
		
		@include breakpoint(small only) {
			font-size: rem-calc(16);
		}
		
		@include breakpoint(medium only) {
			font-size: rem-calc(19);
		}
		
		@include breakpoint(large) {
			font-size: rem-calc(20.8);
		}
	}
	
	.author-photo-wrapper {
		margin-bottom: rem-calc(25);
		img {
			border-radius: 50%;
			width: rem-calc(125);
			height: auto;
			
			
		}
	}
	
	.testimonial-top-line, .testimonial-bottom-line {
		color: $blue;
		text-transform: uppercase;
		
		line-height: 120%;
	}
	
	.testimonial-top-line {
		font-weight: 500;
	}
	
	.testimonial-bottom-line {
		font-weight: 700;
		margin-top: rem-calc(10);
	}
	@include breakpoint(small only) {
		padding: {
			top: rem-calc(160);
			bottom: rem-calc(50);
		}
		
		.testimonial-top-line, .testimonial-bottom-line {
			font-size: rem-calc(16);
		}
	}
	@include breakpoint(medium only) {
		padding: {
			top: rem-calc(105);
			bottom: rem-calc(80);
		}
		
		.testimonial-top-line, .testimonial-bottom-line {
			font-size: rem-calc(18);
		}
	}
	@include breakpoint(large) {
		padding: {
			top: rem-calc(105);
			bottom: rem-calc(80);
		}
		
		h2 {
			font-size: rem-calc(40);
		}
		
		.testimonial-top-line, .testimonial-bottom-line {
			font-size: rem-calc(20);
		}
	}
}



/* CTA - Form */

.cta-form-wrapper {
	
	
	
	.cta-wrapper {
		.grid-x {
			@include breakpoint(medium down) {
				justify-content: center;
			}
			@include breakpoint(large) {
				justify-content: space-between;
			}
		}
		
		.form-wrapper {
			@include breakpoint(medium down) {
				margin-top: rem-calc(64);
			}
			.gform_wrapper {
				margin: 0 !important;
				.gform_body {
					font-family: $form-font-family;
					.gfield {
						.gfield_label {
							color: $white;
							text-transform: uppercase;
							font-weight: 500;
							font-size: rem-calc(14);
							.gfield_required {
								color: $white;
							}
						}
						.ginput_container {
							margin-top: 0 !important;
						}
						
						input, select, textarea {
							box-shadow: none !important;
							border: none !important;
						}
						
						&.gfield_html {
							font-size: rem-calc(13);
							color: $white;
						}
					}
				}
				
				.button {
					margin-left: 0 !important;
					border: 1px solid $kiwi;
					color: $kiwi;
				}
			}
		}
	}
	
	
	@include breakpoint(small only) {
		padding: {
			top: rem-calc(110);
			bottom: rem-calc(50);
		}
	}
	
	@include breakpoint(medium) {
		padding: {
			top: rem-calc(138);
			bottom: rem-calc(64);
		}
	}
	
	@include breakpoint(large) {
		
	}
	
}

/* Pricing Retainer Cards */

.card-wrapper {
	
	padding: rem-calc(19) rem-calc(17);
	
	&.most-popular {
		background: transparent linear-gradient(180deg, rgba(28, 71, 94, 1) 0%, rgba(6, 160, 151, 1) 100%) 0% 0% no-repeat padding-box;
		
	}
	
	.card-inner {
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
		padding: rem-calc(40) rem-calc(28) rem-calc(55) rem-calc(28);
		padding: {
			
		}
		background: $white;
	}
	
	.card-top {
		padding-bottom: rem-calc(19);
		line-height: 1;
		color: $white;
		font-weight: 600;
		font-size: rem-calc(26);
	}
	
	.card-heading {
		margin-bottom: rem-calc(50);
		
		h3 {
			color: $kiwi;
			text-transform: uppercase;
			font-weight: 600;
			position: relative;
			font-size: rem-calc(18);
			margin: 0;
			padding-bottom: rem-calc(8);
			
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				width: rem-calc(140);
				height: rem-calc(3);
				background: $kiwi;
				transform: translateX(-50%);
			}
		}
	}
	
	.card-description {
		color: $blue;
		margin-bottom: rem-calc(50);
	}
	
	.card-price-info {
		color: $blue;
		.card-price-label {
			line-height: 1;
			margin-bottom: 1rem;
		}
		.card-price {
			font-weight: 600;
			line-height: 1;
		}
		.card-price-term {
			font-weight: 600;
		}
		.card-price-points {
			font-style: italic;
		}
	}
	
	.card-button {
		margin-top: rem-calc(38);
	}
	
	@include breakpoint(small only) {
		.card-description {
			font-size: rem-calc(20);
		}
		.card-price-label {
			font-size: rem-calc(18);
		}
		.card-price {
			font-size: rem-calc(40);
		}
		.card-price-term {
			font-size: rem-calc(22);
		}
		.card-price-points {
			font-size: rem-calc(19);
		}
	}
	
	@include breakpoint(medium only) {
		.card-description {
			font-size: rem-calc(20);
		}
		.card-price-label {
			font-size: rem-calc(18);
		}
		.card-price {
			font-size: rem-calc(40);
		}
		.card-price-term {
			font-size: rem-calc(22);
		}
		.card-price-points {
			font-size: rem-calc(19);
		}
	}
	
	@include breakpoint(large) {
		.card-description {
			font-size: rem-calc(26);
		}
		.card-price-label {
			font-size: rem-calc(20);
		}
		.card-price {
			font-size: rem-calc(60);
		}
		.card-price-term {
			font-size: rem-calc(28);
		}
		.card-price-points {
			font-size: rem-calc(24);
		}
	}
}

/* Three Column Cards */

.kiwi-card {
	
	
	padding: rem-calc(16);
	height: 100%;
	
	
	&.is-featured {
		
		background: transparent linear-gradient(180deg, rgba(28, 71, 94, 1) 0%, rgba(6, 160, 151, 1) 100%) 0% 0% no-repeat padding-box; 
		
		.kiwi-card-inner {
			display: flex;
			flex-flow: column nowrap;
			align-items: center;
			justify-content: center;
			//box-shadow: 0px 3px 6px rgba($black, 0.16);
		}
		
		.featured-icon {
			margin-bottom: rem-calc(18);

			svg{
				max-width: 40%;
			}
		}
	}
	
	&:not(.is-featured) {
		background: $white;
		box-shadow: 0px 3px 6px rgba($black, 0.16);
		
	}
	
}

.kiwi-card-inner {
	background: $white;
	
	height: 100%;
	padding: {
		top: rem-calc(38);
		bottom: rem-calc(28);
		left: rem-calc(22);
		right: rem-calc(22);
	}
	.card-title {
		h3 {
			color: $blue;
		}
	}
	
	.card-icon {
		//margin-bottom: rem-calc(22);
		margin-bottom: rem-calc(40);
	}
	
	.card-text {
		padding-bottom: rem-calc(22);
	}
	
	.list-text  {
		ul {
			padding-left: 0 !important;
			li:not(:last-child) {
				margin-bottom: .5rem;
			}
		}

	}
	
	@include breakpoint(small only) {
		
	}
}

/* Three column list cards */
.tclc-heading {
	@include breakpoint(medium) {
		display: inline-block;
	}
	
	background: transparent linear-gradient(96deg, rgba(0, 112, 105, 1) 0%, rgba(20, 65, 88, 1) 100%) 0% 0% no-repeat padding-box;
	
	text-align: center;
	transform: translateY(-50%);
	
	@include breakpoint(small only) {
		padding: rem-calc(16) rem-calc(20);
	}
	
	@include breakpoint(medium) {
		padding: rem-calc(16) rem-calc(42);
	}
	
	h2 {
		color: $white;
		text-transform: uppercase;
		margin: 0;
		letter-spacing: rem-calc(1.88);
		
		@include breakpoint(small only) {
			font-size: rem-calc(18);
		}

		@include breakpoint(medium only) {
			font-size: rem-calc(21);
		}

		@include breakpoint(large) {
			font-size: rem-calc(25);
		}
	}
}

.tclc-grid {
	padding-top: rem-calc(30);
	padding-bottom: rem-calc(90);
	
	.kiwi-card {
		height: 100%;
		&.is-featured {
			height: 100%;

			h3 {
				color: $blue !important;
				@include breakpoint(medium) {
					font-size: rem-calc(24) !important;
				}
			}
		}
		.title-wrapper {
			h3 {
				color: $blue;
			}
		}
		.icon-wrapper {
			width: rem-calc(50);
			
			svg {
				max-width: 100%;
				height: auto;
			}
		}

		&.link-card {
			padding-bottom: 2rem;
			position: relative;
			transition: transform .2s linear;
			&:hover {
				transform: translateY(-3px);
			}

			ul, li {
				color: $body-font-color;
			}

			.link-card-text {
				position: absolute;
				bottom: 0;
				left: 0;
				padding: 0 rem-calc(38) rem-calc(20) rem-calc(38);
				
				
			}

			.list-text {
				& , & p {
					color: $gray !important
				}
			}

			.learn-more-link-text {
				
				color: $blue;
				font-weight: 700;
				position: relative;
				padding-right: rem-calc(30);

				&:after {
					content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="18.648" height="15.514" viewBox="0 0 18.648 15.514"><g id="right-arrow" transform="translate(2 -39.346)"><g id="Group_1770" data-name="Group 1770" transform="translate(-2 39.346)"><path id="Path_4232" data-name="Path 4232" d="M18.35,48.379l-6.735-6.735a1.024,1.024,0,0,0-1.443,0l-.611.612a1.013,1.013,0,0,0-.3.722,1.033,1.033,0,0,0,.3.73l3.929,3.938H1.007a1,1,0,0,0-1.007,1v.865a1.034,1.034,0,0,0,1.007,1.048H13.534L9.561,54.52a1.011,1.011,0,0,0,0,1.433l.611.61a1.024,1.024,0,0,0,1.443,0l6.735-6.735a1.029,1.029,0,0,0,0-1.449Z" transform="translate(0 -41.346)" fill="#C1D831"/></g></g></svg>');
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-35%);
				}
			}

		}
	}
}


/* Text Wide and Media */
.text-wide-media {
	.media-responsive {
		svg {
			max-width: 100%;
			width: 100%;
			height: auto;
		}
	}
	.media-set-width {
		.media-cell {
			
			.tm-media-column-wrapper {
				svg {
					max-width: 100%;
					width: 100%;
					height: auto;
				}
				@include breakpoint(small only) {
					width: 100%;
				}
				@include breakpoint(medium only) {
					width: rem-calc(300);
				}
				@include breakpoint(large) {
					width: rem-calc(404);
				}
			}
			
			@include breakpoint(small only) {
				&.small-order-1 {
					margin-bottom: rem-calc(40);
				}
				&.small-order-2 {
					margin-top: rem-calc(40);
				}
			}
		}
		
		.text-cell {
			
			h2, h3, h4, h5 {
				margin-bottom: 2rem;
			}
			&.medium-order-1 {
				.tm-text-column-wrapper {
					@include breakpoint(medium only) {
						padding-right: rem-calc(40);
					}
					@include breakpoint(large) {
						padding-right: rem-calc(75);
					}
				}
			}
			&.medium-order-2 {
				.tm-text-column-wrapper {
					@include breakpoint(medium only) {
						padding-left: rem-calc(40);
					}
					@include breakpoint(large) {
						padding-left: rem-calc(75);
					}
				}
			}
			
			.button {
				margin-top: 2rem;
			}
		}
	}
}

/* Client Logo Carousel */

.client-logo-carousel {
	h2 {
		margin: 0;
	}
}

.logo-carousel-wrapper.bg-light-gray {
	padding-top: rem-calc(64);
	padding-bottom: rem-calc(64);
}

.client-logo-slider, .partners-logo-slider {
	
	&.global {
		img {
			filter: grayscale(100%);
		}
	}
	
	@include breakpoint(small only) {
		margin-top: rem-calc(32);
	}
	
	@include breakpoint(medium only) {
		margin-top: rem-calc(50);
	}
	
	@include breakpoint(large) {
		margin-top: rem-calc(64);
	}
	
	button.slick-arrow {
		display: none !important;
	}
}

/* Stats Counter */
.stats-counter-wrapper {
	background: $light-gray;
	margin-top: rem-calc(34);
}

.stats-counter-heading {
	display: inline-block;
	background: transparent linear-gradient(96deg, rgba(0, 112, 105, 1) 0%, rgba(20, 65, 88, 1) 100%) 0% 0% no-repeat padding-box;
	padding: rem-calc(16) rem-calc(42);
	text-align: center;
	transform: translateY(-50%);
	
	h2 {
		color: $white;
		text-transform: uppercase;
		margin: 0;
		letter-spacing: rem-calc(1.88);
		
		@include breakpoint(small only) {
			font-size: rem-calc(16);
		}

		@include breakpoint(medium only) {
			font-size: rem-calc(21);
		}

		@include breakpoint(large) {
			font-size: rem-calc(25);
		}
	}
}

.stats-grid {
	padding: {
		bottom: rem-calc(40);
		top: rem-calc(20);
	}
	
	@include breakpoint(medium down) {
		justify-content: center;
	}
	
	@include breakpoint(large) {
		justify-content: space-between;
	}
	
	.stat-wrapper {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		padding-top: rem-calc(20);
		padding-bottom: rem-calc(20);
	}
	
	
	.stat-icon {
		
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: rem-calc(16);
		
		svg {
			max-height: 100%;
			path, g {
				fill: $blue;
			}
		}
		
		@include breakpoint(small only) {
			height: rem-calc(40);
		}
		
		@include breakpoint(medium only) {
			height: rem-calc(55);
		}

		@include breakpoint(large) {
			height: rem-calc(68);
		}
	}
	
	.stat-value-wrapper {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		line-height: 1;
		color: $kiwi;
		font-weight: 600;
		
		@include breakpoint(small only) {
			font-size: rem-calc(50);
		}
		
		@include breakpoint(medium only) {
			font-size: rem-calc(65);
		}

		@include breakpoint(large) {
			font-size: rem-calc(70);
		}
	}
	
	.stat-label {
		color: $blue;
		font-weight: 600;
		
		line-height: 1.111;
		max-width: rem-calc(230);
		text-align: center;
		margin-top: rem-calc(10);
		
		@include breakpoint(small only) {
			font-size: rem-calc(18);
		}
		
		@include breakpoint(medium only) {
			font-size: rem-calc(21);
		}

		@include breakpoint(large) {
			font-size: rem-calc(21);
		}
	}
}

/* Learn More Nav */


.learn-more-nav-wrapper {
	background: $light-gray;
	margin-top: rem-calc(34);
	
	.link-boxes {
		padding: {
			bottom: rem-calc(80);
			top: rem-calc(40);
		}
	}
}

.lmn-heading {
	@include breakpoint(medium) {
		display: inline-block;
	}
	
	background: transparent linear-gradient(96deg, rgba(0, 112, 105, 1) 0%, rgba(20, 65, 88, 1) 100%) 0% 0% no-repeat padding-box;
	
	text-align: center;
	transform: translateY(-50%);
	
	@include breakpoint(small only) {
		padding: rem-calc(16) rem-calc(20);
	}
	
	@include breakpoint(medium) {
		padding: rem-calc(16) rem-calc(42);
	}
	
	h2 {
		color: $white;
		text-transform: uppercase;
		margin: 0;
		letter-spacing: rem-calc(1.88);
		
		@include breakpoint(small only) {
			font-size: rem-calc(18);
		}

		@include breakpoint(medium only) {
			font-size: rem-calc(21);
		}

		@include breakpoint(large) {
			font-size: rem-calc(25);
		}
	}
}

.lmn-box {
	position: relative;
	
	.lmn-title {
		span {
			display: block;
		}
		.bold-text {
			font-weight: 700;
		}
		.medium-text {
			font-weight: 500;
		}
		
		h3 {
			line-height: 1.1;
			margin: {
				bottom: rem-calc(24);
				top: rem-calc(20);
			}
		}
	}
	
	&.hiring {
		h3 {
			color: $blue;
		}
	}
	
	&.working {
		h3 {
			color: $kiwi;
		}
	}
	
	&.best-practices {
		h3 {
			color: $teal;
		}
	}
	
	
	
	@include breakpoint(small only) {
		text-align: center !important;
		
		h3 {
			font-size: rem-calc(30);
		}
	}
	
	@include breakpoint(medium down) {
		&.working, &.hiring {
			margin-bottom: rem-calc(46);
			padding-bottom: rem-calc(46);
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: rem-calc(3);
				background: $white;
			}
			
		}
	}
	
	@include breakpoint(medium only) {
		/*
		&.hiring:before {
			content: '';
			position: absolute;
			top: 0;
			right: -25%;
			height: 100%;
			width: rem-calc(3);
			background: $white;
		}
		
		&.best-practices {
			margin-top: rem-calc(40);
			padding-top: rem-calc(40);
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: rem-calc(3);
				background: $white;
			}
		}
		*/
		h3 {
			font-size: rem-calc(35);
		}
	}
	
	@include breakpoint(large) {
		&.working:before, &.hiring:before {
			content: '';
			position: absolute;
			top: 0;
			right: -20%;
			height: 100%;
			width: rem-calc(3);
			background: $white;
		}
		h3 {
			font-size: rem-calc(40);
		}
	}
}

/* Rated 5 Stars Callout */
.rfsc {
	.rfsc-graphic {
		margin-bottom: rem-calc(24);
		svg {
			overflow: visible;
			max-width: 100%;
			height: auto;
		}
	}
	
	h3 {
		color: $blue;
		margin-bottom: rem-calc(28);
	}
}


/* contact nav */
.cn-heading {
	margin-bottom: rem-calc(65);
	
	h2 {
		margin-bottom: 0;
	}
}

.contact-nav-block {
	input[type=submit].button:hover {
		background: $blue !important;
	}
}
#contact-nav {
	border: none;

	
	
	
	/* Accordion */
	@include breakpoint(small only) {
		.accordion-item {
			padding-bottom: rem-calc(32);
			border-bottom: 1px solid $light-gray;
			&:not(:first-child) {
				padding-top: rem-calc(32);
				
			}
			
			
			
			a.accordion-title {
				padding: 0;
				&:before {
					content: '';
					position: absolute;
					top: 50%;
					right: 0;
					margin: 0;
					background: {
						size: contain;
						position: center center;
						repeat: no-repeat;
					}
					transform: translateY(-50%);
				}
				
				.tab-nav-title {
					font-size: rem-calc(18);
				}
				
			}
			
			&.is-active a.accordion-title:before {
				/*
				height: calc(rem-calc(16.08) * 0.7);
				width: calc(rem-calc(20.75) * 0.7);
				*/
				height: rem-calc(11.256);
				width: rem-calc(14.525);
				background-image: url(../images/svg/acc-arrow-open.svg);
			}
			
			&:not(.is-active) a.accordion-title:before {
				width: rem-calc(11.256);
				height: rem-calc(14.525);
				background-image: url(../images/svg/acc-arrow-closed.svg);
			}
			
			.tab-wrapper {
				padding-right: rem-calc(40);
			}
			
			.tab-inner {
				@include flex;
				@include flex-align(justify, middle);
				.tab-icon {
					@include xy-cell-base(shrink);
					padding-right: rem-calc(20);
					svg {
						width: rem-calc(36);
						height: auto;
					}
				}
				
				.tab-nav-title {
					@include xy-cell-base(auto);
					text-align: left !important;
					color: $teal;
					font: {
						size: rem-calc(20);
						weight: 600;
					}
				}
			}
			
			.accordion-content {
				padding: {
					left: 0;
					right: 0;
					top: rem-calc(38);
				}
				
				h2.has-accent {
					margin-bottom: rem-calc(40);
				}
				
				.contact-info {
					margin-bottom: rem-calc(40);
				}
				
				.carousel-heading {
					margin-top: rem-calc(65);
				}
			}
		}
	}
	
	/* Tabs */
	@include breakpoint(medium) {
		margin: {
			left: rem-calc(-15);
			right: rem-calc(-15);
		}
		display: flex;
		flex-direction: row;
		.tabs-title {
			float: none !important;
			flex: 1 1 auto;
			-webkit-box-flex: 1;
			padding: {
				left: rem-calc(15);
				right: rem-calc(15);
			}
			
			.tab-wrapper {
				padding: rem-calc(15);
			}
			
			a, .tab-wrapper {
				height: 100%;
			}
			
			a {
				padding: 0;
				background: transparent;
				
				&[aria-selected=true] {
					background: transparent;
				}
				
				.tab-nav-title {
					color: $teal;
					
					font-weight: 500;
				}
			}
			
			
			&:not(.is-active) .tab-wrapper {
				box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
				background: $white;
				transition: all .3s ease-in-out;
				
				&:hover {
					box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
					transform: translateY(1px);
				}
			}
			
			&.is-active .tab-wrapper {
				background: transparent linear-gradient(180deg, rgba(28, 71, 94, 1) 0%, rgba(6, 160, 151, 1) 100%) 0% 0% no-repeat padding-box;
				position: relative;
				z-index: 0;
				&:after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 50%;
					width: rem-calc(39.99);
					height: rem-calc(17.7);
					z-index: -1;
					background: {
						image: url(../images/svg/contact-navigation-active-triangle.svg);
						size: contain;
						position: center center;
						repeat: no-repeat;
					}
					transform: translate(-50%, 95%);
				}
				
				
			}
			
			.tab-inner {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background: $white;
				padding: rem-calc(30);
				height: 100%;
				
				.tab-icon {
					
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: rem-calc(15);
					
					svg {
						max-height: 100%;
						width: auto;
					}
				}
			}
		}
	}
	
	@include breakpoint(medium only) {
		.tab-nav-title {
			font-size: rem-calc(24);
		}
		
		.tab-icon {
			height: rem-calc(60);
		}
	}
	
	@include breakpoint(large) {
		.tab-nav-title {
			font-size: rem-calc(28);
		}
		
		.tab-icon {
			height: rem-calc(86);
		}
	}
	
}

#contact-tab {
	.contact-info h3 {
		color: $blue;
		margin-bottom: rem-calc(18);
	}
	
	.address {
		margin-bottom: rem-calc(24);
	}
	.phone a {
		color: $gray;
	}
	.social-media {
		margin-top: rem-calc(65);
		
		li:not(:first-child) {
			margin-left: rem-calc(10);
		}
		a {
			padding: 0 !important;
		}
		svg {
			height: rem-calc(45);
			width: rem-calc(45);
			path {
				fill: $teal;
				transition: fill .3s ease-in-out;
			}
			
			&:hover path {
				fill: $kiwi;
			}
		}
	}
	
	.carousel-heading {
		margin-top: rem-calc(120);
		h3 {
			margin-bottom: 0;
		}
	}
	
	.carousel-wrapper {
		margin-top: rem-calc(50);
		
	}
}

@include breakpoint(medium) {
	.contact-nav-block .tabs-content {
		border: none !important;
		padding-top: rem-calc(65);
		.tabs-panel {
			padding: 0;
			
			h2 {
				margin-bottom: rem-calc(50);
			}
		}
	}
}

.slide-inner {
	margin: 0 .7rem;
}
.slick-arrow {
	cursor: pointer;
}

/* Text Wide with Short Cards */
.wtsc {
	.wtsc-card {
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
		cursor: pointer;
		transition: all .2s ease-in-out;
		
		
		a {
			color: $blue;
			.reg-text, .bold-text {
				line-height: 1;
			}
		}
		
		
		&:hover {
			box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
			transform: translateY(2px);
		}
		
		&:not(:first-child) {
			margin-top: rem-calc(16);
		}
		
		.reg-text {
			font-weight: 400;
		}
		.bold-text {
			font-weight: 600;
			display: inline-block;
			line-height: 1;
			position: relative;
			
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				right: 0;
				@include breakpoint(small only) {
					height: rem-calc(9.61 * 1.5);
					width: rem-calc(4.78 * 1.5);
					transform: translate(15px, -35%);
				}
				@include breakpoint(medium only) {
					height: rem-calc(9.61 * 1.25);
					width: rem-calc(4.78 * 1.25);
					transform: translate(12px, -35%);
				}
				@include breakpoint(large) {
					height: rem-calc(9.61 * 1.75);
					width: rem-calc(4.78 * 1.75);
					transform: translate(20px, -35%);
				}
				background: {
					image: url(../images/svg/short-cards-arrow.svg);
					size: contain;
					position: center center;
					repeat: no-repeat;
				}
				
			}
		}
	}
	.icon-wrapper {
		text-align: center;
	}
	
	@include breakpoint(small only) {
		.text-wrapper {
			padding-bottom: rem-calc(40);
		}
		.wtsc-card {
			padding: rem-calc(20) rem-calc(30);
		}
		
		
		.icon-wrapper {
			width: rem-calc(40);
			
			svg {
				max-width: 100%;
				max-height: rem-calc(40);
			}
		}
		
		.reg-text {
			font-size: rem-calc(18);
		}
		
		.bold-text {
			font-size: rem-calc(26);
		}
	}
	
	@include breakpoint(medium only) {
		.text-wrapper {
			padding-right: 5%;
		}
		.wtsc-card {
			padding: rem-calc(18) rem-calc(24);
		}
		.cards-wrapper {
			width: rem-calc(300);
		}
		
		.icon-wrapper {
			width: rem-calc(50);
			
			svg {
				max-width: 100%;
				max-height: rem-calc(50);
			}
		}
		
		.reg-text {
			font-size: rem-calc(19);
		}
		
		.bold-text {
			font-size: rem-calc(28);
		}
	}
	
	@include breakpoint(large) {
		.wtsc-card {
			padding: rem-calc(24) rem-calc(38);
		}
		.text-wrapper {
			padding-right: 10%;
		}
		.cards-wrapper {
			width: rem-calc(410);
		}
		
		.icon-wrapper {
			width: rem-calc(80);
			
			svg {
				max-width: 100%;
				max-height: rem-calc(80);
			}
		}
		
		.reg-text {
			font-size: rem-calc(31);
		}
		
		.bold-text {
			font-size: rem-calc(38);
		}
	}
}

/* Two Column Checklist */
.two-column-checklists {
	.checklist-box {
		@include breakpoint(medium) {
			height: 100%;
		}
		
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
		padding: rem-calc(18);
		
		h3 {
			color: $blue;
		}
		
		&.mindset {
			background: transparent linear-gradient(180deg, rgba(28, 71, 94, 1) 0%, rgba(6, 160, 151, 1) 100%) 0% 0% no-repeat padding-box;
			@include breakpoint(small only) {
				margin-top: rem-calc(32);
			}
		}
	}
	
	.checklist-box-inner {
		background: $white;
		padding: rem-calc(42) rem-calc(30);
		@include breakpoint(medium) {
			height: 100%;
		}
		
		ul {
			margin: 0;
			padding-left: 0 !important;
			list-style: none;
			
			li {
				padding-left: rem-calc(28);
				position: relative;
				
				margin-bottom: 1rem;
				
				&:before {
					content: '';
					position: absolute;
					top: 6px;
					left: 0;
					background: {
						size: contain;
						position: center center;
						repeat: no-repeat;
					}
				}
			}
			
			&.check-list {
				li:before {
					width: rem-calc(18.34);
					height: rem-calc(13.07);
					background-image: url(../images/svg/green-check.svg);
				}
			}
			&.x-list {
				li:before {
					width: rem-calc(10.01);
					height: rem-calc(9.76);
					background-image: url(../images/svg/red-x.svg);
				}
			}
		}
	}
}

/* Wide Table with Text */
.wtt {
	h3.has-accent {
		margin-bottom: rem-calc(32);
	}
	
	.button {
		margin-top: rem-calc(50);
	}
	
	.text-column-wrapper {
		@include breakpoint(medium down) {
			margin-top: rem-calc(64);
		}
		
		ul {
			li {
				&:not(:first-child) {
					margin-top: rem-calc(20);
				}
				
				.list-label {
					font-weight: 600;
				}
			}
		}
	}
}

table {
	border: none;
	thead {
		background: transparent;
		color: $blue;
		font-weight: 600;
		
		
		border: none;
		
		@include breakpoint(small only) {
			font-size: rem-calc(16);
		}
		@include breakpoint(medium) {
			font-size: rem-calc(20);
		}
	}
	tbody {
		border: none;
		tr {
			&:nth-child(odd) {
				background: $light-gray;
			}
			&:nth-child(even) {
				background: #E0E0E0;
			}
		}
	}
	
	tr {
		th, td {
			@include breakpoint(small only) {
				padding: rem-calc(10) rem-calc(20);
			}
			@include breakpoint(medium) {
				padding: rem-calc(10) rem-calc(40);
			}
			
		}
	}
}

/* Blocks with Green Textured Background */
.green-textured-block {
	position: relative;
	padding: {
		top: rem-calc(110);
		bottom: rem-calc(90);
	}
	background: {
		image: url(../images/svg/graphic-homepage-form-block-pattern-gradient.svg);
		size: cover;
		position: center center;
		repeat: no-repeat;
	}
	
	color: $white;
	
	.short-headline {
		position: absolute;
		top: 0;
		left: 50%;
		padding: rem-calc(16) rem-calc(47);
		background: $kiwi;
		display: inline-block;
		transform: translate(-50%, -50%);
		
		@include breakpoint(medium only) {
			width: 60%
		}
		
		@include breakpoint(small only) {
			width: 85%;
		}
		
		h2 {
			text-transform: uppercase;
			font-weight: 600;
			color: $white;
			margin: 0;
			@include breakpoint(small only) {
				font-size: rem-calc(16);
			}
			@include breakpoint(medium only) {
				font-size: rem-calc(20);
			}
			@include breakpoint(large) {
				font-size: rem-calc(25);
			}
		}
	}
	
	h3 {
		position: relative;
		color: $white;
		margin-bottom: rem-calc(30);
		padding-bottom: rem-calc(40);
		
		
		
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: rem-calc(3);
			background: rgba($white, .15);
			transform: translateY(50%);
		}
	}
	
	p {
		color: $white;
		
	}
	
	.snarky-text {
		color: $kiwi;
		line-height: 1.6;
		font-weight: 600;
		margin-top: rem-calc(40);
		
	}
	
	.button {
		margin-top: rem-calc(60);
	}
	
	@include breakpoint(small only) {
		h3 {
			font-size: rem-calc(25);
		}	
		
		p {
			font-size: rem-calc(16);
		}
		
		.snarky-text {
			font-size: rem-calc(16);
		}
	}

	@include breakpoint(medium) {
		h3 {
			font-size: rem-calc(40);
		}
		
		p {
			font-size: rem-calc(18);
		}
		
		.snarky-text {
			font-size: rem-calc(18);
		}
	}
	@include breakpoint(large) {
		h3 {
			font-size: rem-calc(40);
		}
		
		p {
			font-size: rem-calc(18);
		}
		
		.snarky-text {
			font-size: rem-calc(18);
		}
		
	}
}

.skip-form {
	margin-top: rem-calc(32);
	
	.skip-form-text {
		display: inline-flex;
		flex-flow: row nowrap;
		
		
		@include breakpoint(medium) {
			align-items: center;
		}
		
		svg {
			margin-right: rem-calc(8);
			width: rem-calc(16);
			height: auto;
			path {
				fill: $white;
			}
			
			@include breakpoint(small only) {
				align-self: flex-start;
				transform: translateY(5px);
			}
		}
		
		.skip-link {
			cursor: pointer;
			text-decoration: underline;
		}
	}
}

.reveal {
	.reveal-inner {
		
		h3 {
			text-align: center;
		}
		
		@include breakpoint(small only) {
			padding-top: rem-calc(48);
		}
		
		@include breakpoint(medium) {
			padding: rem-calc(48) rem-calc(24) rem-calc(24) rem-calc(24);
		}
	}
	
	.close-button {
		svg {
			path {
				fill: $blue;
			}
		}
	}
}

/* Single Resource CTA */
.single-resource-cta {
	.cta-wrapper {
		> .grid-x {
			@include breakpoint(medium down) {
				justify-content: center;
			}
			@include breakpoint(large) {
				justify-content: space-between;
			}
		}
		
		.cta-image-wrapper {
			text-align: center;
			@include breakpoint(medium down) {
				margin-bottom: rem-calc(40);
			}
		}
	}
}

/* General CTA */
.general-cta {
	padding-top: rem-calc(85);
	padding-bottom: rem-calc(50);
	.cta-wrapper {
		padding-top: rem-calc(97);
		padding-bottom: rem-calc(97);
		position: relative;
	}

	

	.cta-box {
		padding: rem-calc(15);
	}

	.cta-box-inner {
		
		@include breakpoint(small only) {
			padding: rem-calc(40);
		}
		@include breakpoint(medium) {
			padding: rem-calc(64) rem-calc(80);
		}

		h3 {
			color: $dark-blue;
			margin-bottom: rem-calc(10);
			@include breakpoint(small only) {
				font-size: rem-calc(25);
			}
			@include breakpoint(medium) {
				font-size: rem-calc(40);
			}
		}

		p {
			line-height: 1.4;
			@include breakpoint(medium) {
				font-size: rem-calc(28);
			}
		}

		.button {
			@include breakpoint(small only) {
				margin-top: rem-calc(20);
			}
			@include breakpoint(medium) {
				margin-top: rem-calc(35);
			}
		}

		
	}

}

.short-heading-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.short-heading {
	transform: translateY(-50%);
	background: transparent linear-gradient(97deg, #007069 0%, #144158 100%) 0% 0% no-repeat padding-box;
	padding: rem-calc(16) rem-calc(20);
	text-align: center;

	h2 {
		color: $white;
		text-transform: uppercase;
		letter-spacing: rem-calc(1.88);
		margin: 0 !important;

		@include breakpoint(small only) {
			font-size: rem-calc(16);
		}

		@include breakpoint(medium only) {
			font-size: rem-calc(21);
		}

		@include breakpoint(large) {
			font-size: rem-calc(25);
		}
	}
}

.bg-gradient {
	background: transparent linear-gradient(115deg, #007069 0%, #025854 100%) 0% 0% no-repeat padding-box;
}

.bg-white {
	background: $white;
}

/* Portfolio callout */
.portfolio-callout-block.bg-gray {
	position: relative;
	.portfolio-callout {
		@include breakpoint(small only) {
			padding-top: rem-calc(100);
			padding-bottom: rem-calc(64);
		}
		@include breakpoint(medium) {
			padding-top: rem-calc(132);
			padding-bottom: rem-calc(100);
		}
	}
}

/**********************
New CTA
**********************/

.cta-new {
	overflow: hidden;
}

.cta-new-inner {
	background: rgb(28,71,94);
	
	
	.grid-container {
		position: relative;
		z-index: 0;
		padding-top: rem-calc(85);
		padding-bottom: rem-calc(85);
	}

	@include breakpoint(1399px down) {
		.cta-new-bg {
			display: none;
		}
	}
	
	@include breakpoint(1400px) {
		background: linear-gradient(0deg, rgba(28,71,94,1) 0%, rgba(6,160,151,1) 100%);
		.cta-new-bg {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			transform: translateX(-18.5%);
			z-index: -1;
	
			svg {
				width: 140%;
				object-fit: cover;
				height: 100%;
			}
			
		}
	}
	
}

.cta-new-wrapper {
	position: relative;
	z-index: 0;

	.cta-border {
		position: absolute;
		z-index: -1;

		&.top-left {
			top: 0;
			left: 0;
		}
		&.top-right {
			top: 0;
			right: 0;
		}
		&.bottom-left {
			bottom: 0;
			left: 0;
		}
		&.bottom-right {
			bottom: 0;
			right: 0;
		}
	}
}

.cta-new-content {
	padding-top: rem-calc(100);
	padding-bottom: rem-calc(100);
	padding-left: 8%;
	padding-right: 8%;

	h2 {
		color: $white;
	}

	p {
		color: $white;
		@include breakpoint(medium) {
			font-size: rem-calc(28);
		}
		

	}

	.button {
		margin-top: rem-calc(40);
	}
}

/**********************
Process Slider
**********************/

.ps-heading {
	@include breakpoint(medium down) {
		margin-bottom: rem-calc(40);
	}
}

.process-slider {
	padding-top: rem-calc(64);
	padding-bottom: rem-calc(64);
	.orbit-wrapper {
		position: relative;

		.orbit-controls {
			position: absolute;

			@include breakpoint(small only) {
				bottom: rem-calc(-43);
				left: 50%;
				transform: translateX(-50%);
				z-index: 1;
				
			}
			
			@include breakpoint(medium) {
				right: 0;
			}
			@include breakpoint(medium only) {
				bottom: 0;
			}
			@include breakpoint(large) {
				top: rem-calc(30);	
			}
			
			
			

			.orbit-previous, .orbit-next {
				position: relative !important;
				cursor: pointer;
				padding: 0;

				&:hover, &:focus, &:active {
					background: transparent !important;
				}
			}

			.orbit-previous {
				margin-right: rem-calc(19);
			}
		}
	}

	.orbit-slide {
		@include breakpoint(small only) {
			padding-bottom: rem-calc(64);
		}
		.slide-content {
			h3 {
				color: $blue !important;
			}
			@include breakpoint(medium down) {
				padding-top: rem-calc(40);
			}
			@include breakpoint(medium only) {
				padding-right: rem-calc(20);
			}
			@include breakpoint(large) {
				padding-right: rem-calc(115);
				padding-top: rem-calc(50);
				padding-bottom: rem-calc(50);
			}
		}
	}
	.orbit-bullets {
		@include breakpoint(small only) {

		}
		@include breakpoint(medium down) {
			/*
			position: relative;
			justify-content: center;
			display: flex;
			*/
			display: none;
		}
		@include breakpoint(medium only) {
			height: rem-calc(180);
		}
		@include breakpoint(large) {
			margin-bottom: rem-calc(50);
			margin-top: rem-calc(50);
		}
		
		button {
			
			height: auto !important;
			background: none !important;
			cursor: pointer;
			

			
			.slide-num-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				
				transition: all 0.3s ease-in-out;
				position: relative;
				background: {
					position: center center;
					size: contain;
					repeat: no-repeat;
				}
			}

			.slide-title {
				font-size: rem-calc(20);
				font-weight: 600;
				transition: color .3s ease-in-out;
				padding-top: rem-calc(30);
				line-height: 1.3;
			}

			&.is-active {
				.slide-num-wrapper {
					background-image: url('../images/svg/icon-process-color.svg');
				}
				.slide-title {
					color: $dark-blue;
				}
			}

			&:not(.is-active) {
				.slide-num-wrapper {
					background-image: url('../images/svg/icon-process-gray.svg');
				}
				.slide-title {
					color: #bebebe;
				}
			}

			.slide-num-inner {
				
				font-weight: 600;
				color: $white;
			}

			@include breakpoint(medium only) {
				width: auto !important;
				display: flex;
				align-items: center;
				flex-flow: row nowrap;
				.slide-num-wrapper {
					height: rem-calc(126.25) !important;
					width: rem-calc(126.25) !important;
				}

				.slide-num-inner {
					font-size: rem-calc(53) !important;
					
				}
				.slide-title {
					padding-top: 0; 
					padding-left: rem-calc(20);
					font-size: rem-calc(32);
					width: rem-calc(350);
					text-align: left;
				}

				
			}

			@include breakpoint(medium down) {
				position: absolute;

				&:not(.is-active) {
					opacity: 0;
					visibility: hidden;
					pointer-events: none;
				}
			}

			@include breakpoint(large) {
				&:not(:first-child) {


					margin-left: rem-calc(50);
	
	
					.slide-num-wrapper:before {
						content: '';
						position: absolute;
						height: rem-calc(4);
						width: rem-calc(50);
						top: 50%;
						left: rem-calc(-25);
						transform: translate(-100%, -50%);
						background: rgb(209,209,209);
						pointer-events: none;
					}
	
				}
	
				&:not(:last-child) {
					margin-right: rem-calc(50);
				}
	
			}

			@include breakpoint(large only) {
				width: rem-calc(100) !important;
				.slide-num-wrapper {
					height: rem-calc(100);
					width: rem-calc(100);
				}

				.slide-num-inner {
					font-size: rem-calc(45);
				}

				&:not(:first-child) {


					margin-left: rem-calc(25);
	
	
					.slide-num-wrapper:before {
						content: '';
						position: absolute;
						height: rem-calc(4);
						width: rem-calc(25);
						top: 50%;
						left: rem-calc(-15);
						transform: translate(-100%, -50%);
						background: rgb(209,209,209);
						pointer-events: none;
					}
	
				}
			}


			@include breakpoint(1200px) {
				width: rem-calc(126.5) !important;
				.slide-num-wrapper {
					height: rem-calc(126.25) !important;
					width: rem-calc(126.25) !important;
				}

				.slide-num-inner {
					font-size: rem-calc(53) !important;
				}

				&:not(:first-child) {


					margin-left: rem-calc(50);
	
	
					.slide-num-wrapper:before {
						content: '';
						position: absolute;
						height: rem-calc(4);
						width: rem-calc(50);
						top: 50%;
						left: rem-calc(-27);
						transform: translate(-100%, -50%);
						background: rgb(209,209,209);
						pointer-events: none;
					}
	
				}
			}
 		}
	}

	
}