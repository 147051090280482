/************************************************

Stylesheet: Global Stylesheet

*************************************************/

#rank-math-analytics-stats-wrapper, #wpadminbar.mobile {
	display: none !important;
}

/**********************
GLOBAL STYLES
**********************/

.no-bottom-padding {
	padding-bottom: 0 !important;
}

.grid-container {
	@include breakpoint(small only) {
		padding: {
			left: 2rem;
			right: 2rem;
		}
	}
	
	@include breakpoint(medium) {
		padding: {
			left: 3rem;
			right: 3rem;
		}
	}
	
}

.block-expand {
	width: 100vw !important;
	left: 50% !important;
	right: 50% !important;
	margin-left: -50vw !important;
	margin-right: -50vw !important;
	position: relative;
}

.button {
	border-color: $blue;
	text-transform: uppercase;
	font-weight: 700;
	overflow: hidden;
	position: relative;
	z-index: 0;

	&.with-arrow {
		padding-right: rem-calc(48);
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			right: rem-calc(20);
			width: rem-calc(19);
			height: rem-calc(16);
			transform: translateY(-50%);
			background: {
				position: center center;
				repeat: no-repeat; 
				size: contain;
				image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="18.648" height="15.514" viewBox="0 0 18.648 15.514"><g id="right-arrow" transform="translate(2 -39.346)"><g id="Group_1770" data-name="Group 1770" transform="translate(-2 39.346)"><path id="Path_4232" data-name="Path 4232" d="M18.35,48.379l-6.735-6.735a1.024,1.024,0,0,0-1.443,0l-.611.612a1.013,1.013,0,0,0-.3.722,1.033,1.033,0,0,0,.3.73l3.929,3.938H1.007a1,1,0,0,0-1.007,1v.865a1.034,1.034,0,0,0,1.007,1.048H13.534L9.561,54.52a1.011,1.011,0,0,0,0,1.433l.611.61a1.024,1.024,0,0,0,1.443,0l6.735-6.735a1.029,1.029,0,0,0,0-1.449Z" transform="translate(0 -41.346)" fill="#C1D831"/></g></g></svg>');
			}
		}
	}
	
	&:not(.solid-white):not(.hollow-white):after {
		content: '';
		position: absolute;
		z-index: -1;
		width: 0;
		height: 100%;
		top: 50%;
		left: 50%;
		background: $blue;
		opacity: 0;
		transform: translate(-50%, -50%);
		transition: all .3s ease;
	}
	
	&:not(.solid-white):not(.hollow-white):hover {
		color: $white;
	}
	
	&:not(.solid-white):not(.hollow-white):hover:after {
		width: 102%;
		opacity: 1;
	}

	&.solid-blue {
		background-color: $blue;
		color: $white;
		display: inline-block !important; 
		&:after {
			background: $white !important;
		}
		&:hover {
			background: transparent !important;
			color: $blue !important;
		}
	}
	
	&.solid-white {
		border-color: $white;
		color: $blue;
		&:after {
			content: '';
			position: absolute;
			z-index: -1;
			width: 102%;
			height: 100%;
			top: 50%;
			left: 50%;
			background: $white !important;
			opacity: 1;
			transform: translate(-50%, -50%);
			transition: all .3s ease;
		}
		&:hover{
			color: $white;
			&:after {
				width: 0;
				opacity: 0;
			}
		}
	}
	
	&.hollow-white {
		border-color: $white;
		color: $white;
		background: transparent;
		z-index: 1;
		
		&:after {
			content: '';
			position: absolute;
			z-index: -1;
			width: 0;
			height: 100%;
			top: 50%;
			left: 50%;
			background: $white;
			opacity: 0;
			transform: translate(-50%, -50%);
			transition: all .3s ease;
		}
		
		&:hover {
			color: $dark-blue;
		}

		&:hover:after {
			width: 102%;
			opacity: 1;
		}
	}

	&.hollow-kiwi {
		border-color: $kiwi;
		color: $white;
		background: transparent;
		z-index: 1;
		
		&:after {
			content: '';
			position: absolute;
			z-index: -1;
			width: 0;
			height: 100%;
			top: 50%;
			left: 50%;
			background: $kiwi !important;
			opacity: 0;
			transform: translate(-50%, -50%);
			transition: all .3s ease;
		}
		
		&:hover {
			color: $white;

			&:before {
				background: {
					position: center center;
					repeat: no-repeat; 
					size: contain;
					image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="18.648" height="15.514" viewBox="0 0 18.648 15.514"><g id="right-arrow" transform="translate(2 -39.346)"><g id="Group_1770" data-name="Group 1770" transform="translate(-2 39.346)"><path id="Path_4232" data-name="Path 4232" d="M18.35,48.379l-6.735-6.735a1.024,1.024,0,0,0-1.443,0l-.611.612a1.013,1.013,0,0,0-.3.722,1.033,1.033,0,0,0,.3.73l3.929,3.938H1.007a1,1,0,0,0-1.007,1v.865a1.034,1.034,0,0,0,1.007,1.048H13.534L9.561,54.52a1.011,1.011,0,0,0,0,1.433l.611.61a1.024,1.024,0,0,0,1.443,0l6.735-6.735a1.029,1.029,0,0,0,0-1.449Z" transform="translate(0 -41.346)" fill="#ffffff"/></g></g></svg>') !important;
				}
			}
		}

		&:hover:after {
			width: 102%;
			opacity: 1;
		}
	}

	&.solid-kiwi {
		background: $kiwi;
		color: $dark-blue;

		&:after {
			background: $white !important;
		}

		&:hover {
			color: $dark-blue !important;
		}

		&:not(.hollow-hover) {
			border: none;
		}

		&.hollow-hover {
			border: 1px solid $kiwi;

			&:hover {
				background: transparent;
				border-color: $dark-blue;
			}
		}

		&.with-arrow {
			padding-right: rem-calc(48);
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				right: rem-calc(20);
				width: rem-calc(19);
				height: rem-calc(16);
				transform: translateY(-50%);
				background: {
					position: center center;
					repeat: no-repeat; 
					size: contain;
					image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="18.648" height="15.514" viewBox="0 0 18.648 15.514"><g id="right-arrow" transform="translate(2 -39.346)"><g id="Group_1770" data-name="Group 1770" transform="translate(-2 39.346)"><path id="Path_4232" data-name="Path 4232" d="M18.35,48.379l-6.735-6.735a1.024,1.024,0,0,0-1.443,0l-.611.612a1.013,1.013,0,0,0-.3.722,1.033,1.033,0,0,0,.3.73l3.929,3.938H1.007a1,1,0,0,0-1.007,1v.865a1.034,1.034,0,0,0,1.007,1.048H13.534L9.561,54.52a1.011,1.011,0,0,0,0,1.433l.611.61a1.024,1.024,0,0,0,1.443,0l6.735-6.735a1.029,1.029,0,0,0,0-1.449Z" transform="translate(0 -41.346)" fill="%23007069"/></g></g></svg>');
				}
			}
		}

	}
	
	@include breakpoint(small only) {
		font-size: rem-calc(16) !important;
	}
}

.bg-gray, .bg-light-gray {
	background: $light-gray;
}

.bg-gray-graphic {
	background: {
		position: center center;
		repeat: no-repeat;
		size: cover;
		image: url('../images/svg/tclc-bg-gray.svg');
	}
}

@include breakpoint(1249px down) {
	.show-for-1250 {
		display: none;
	}
	.hide-for-1250 {
		display: block;
	}
}

@include breakpoint(1250px) {
	.show-for-1250 {
		display: block;
	}
	.hide-for-1250 {
		display: none;
	}
}


.svg-blue {
	g, path {
		fill: $blue !important;
	}
}


/*********************
Typography Styles
*********************/

h1 {
	color: $blue;
}

h2 {
	color: $blue;
	font-weight: 600;
}

h3 {
	color: $teal;
	font-weight: 600;
}

h4 {
	color: $blue;
	font-weight: 600;
}

h2, h3, h4, h5 {
	&.has-accent {
		position: relative;
		padding-bottom: rem-calc(30);
		
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: rem-calc(188);
			//width: 0;
			height: rem-calc(4);
			background: $kiwi;
		}
	}
}

.tm-heading-wrapper {
	&.has-accent {
		
		padding-bottom: rem-calc(20);
		h2, h3, h4, h5 {
			margin-bottom: rem-calc(30) !important;
		}

		.accent-line {
			height: rem-calc(4);
			background: $kiwi;
			width: 0;
		}
	}
}

ul:not(.menu):not(.services-list):not(.accordion):not(.tabs):not(.gform_fields):not(.orbit-container), ol {
	padding-left: rem-calc(16);
}

ul.two-columns {
	column-count: 2;
	-webkit-column-count: 2;
	-moz-column-count: 2;
}

.block-labels {
	h2 {
		display: inline-block;
		background: $kiwi;
		padding: rem-calc(16) rem-calc(47);
		text-transform: uppercase;
		color: $white;
		font-weight: 600;
		font-size: rem-calc(25);
	}
}

.menu .active > a, .menu .is-active>a {
	background: none !important;
}

a.arrow-link {
	color: $blue;
	display: inline-block !important;
	padding-right: rem-calc(28) !important;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		right: rem-calc(0);
		width: rem-calc(19);
		height: rem-calc(16);
		transform: translateY(-50%);
		background: {
			position: center center;
			repeat: no-repeat; 
			size: contain;
			image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="18.648" height="15.514" viewBox="0 0 18.648 15.514"><g id="right-arrow" transform="translate(2 -39.346)"><g id="Group_1770" data-name="Group 1770" transform="translate(-2 39.346)"><path id="Path_4232" data-name="Path 4232" d="M18.35,48.379l-6.735-6.735a1.024,1.024,0,0,0-1.443,0l-.611.612a1.013,1.013,0,0,0-.3.722,1.033,1.033,0,0,0,.3.73l3.929,3.938H1.007a1,1,0,0,0-1.007,1v.865a1.034,1.034,0,0,0,1.007,1.048H13.534L9.561,54.52a1.011,1.011,0,0,0,0,1.433l.611.61a1.024,1.024,0,0,0,1.443,0l6.735-6.735a1.029,1.029,0,0,0,0-1.449Z" transform="translate(0 -41.346)" fill="#C1D831"/></g></g></svg>');
		}
	}
}

/*********************
Header Styles 
*********************/

.theme-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background: transparent;
	z-index: 10;
	
	.header-top {
		background: rgba($dark-blue, .6);
		padding: {
			top: rem-calc(12);
			bottom: rem-calc(12);
		}
		
		#top-links {
			li {
				
				
				&:not(:first-child) {
					border-left: 1px solid $white;
					padding-left: rem-calc(12);
				}
				&:not(:last-child) {
					padding-right: rem-calc(12);
				}
				a {
					padding: 0;
					color: $white;
					line-height: 1;
					text-transform: uppercase;
					font: {
						weight: 500;
						size: rem-calc(10);
					}
				}
			}
		}
	}
	
	.header-logo-wrapper {
		@include breakpoint(small only) {
			width: rem-calc(160);
		}
		@include breakpoint(medium only) {
			width: rem-calc(200);
		}
		@include breakpoint(large) {
			width: rem-calc(220);
		}
		
		img, svg {
			width: 100%;
			height: auto;
		}
	}
	
	.header-main {
		padding-top: rem-calc(35);
		
		#main-nav {
			align-items: center;
			> li {
				padding-top: rem-calc(6);
				padding-bottom: rem-calc(6);

				&:hover a:before {
					background-color: $kiwi;
				}

				&.active {
					a {
						background: none;
					}
				}
				&:not(:first-child) {
					//padding-left: .5rem;
					padding-left: 0.75rem;
				}
				&:not(:last-child) {
					//padding-right: .5rem;
					padding-right: 0.75rem;
				}
				> a {
					color: $white;
					text-transform: uppercase;
					padding: 0;
					position: relative;
					letter-spacing: .02rem;
					font: {
						weight: 700;
						//size: rem-calc(17);
						size: 1rem;
						
					}
					
					&:before {
						content: '';
						position: absolute;
						top: rem-calc(-10);
						left: 0;
						width: 100%;
						height: 2px;
						background-color: transparent;
						transition: background .3s ease;
					}
					
					
					
				}
				
				&:not(.is-dropdown-submenu-parent) {
					
				}
				
				//&.is-dropdown-submenu-parent {
				&.menu-item-has-children, &.mega-menu-parent {
					> a {
						padding-right: rem-calc(15);
						
						&:before {
							width: calc(100% - 15px) !important;
						}
						
						
						&:after {
							content: '';
							position: absolute;
							top: 50%;
							right: 0;
							margin: 0 !important;
							height: rem-calc(5.55) * 1.20;
							width: rem-calc(7.46) * 1.20;
							transform: translateY(-50%) rotate(0);
							transform-origin: center;
							background: {
								image: url(../images/svg/menu-arrow-down.svg);
								size: contain;
								position: center center;
								repeat: no-repeat;
							}
						}
					}
					
					&.is-active > a {
						
						&:after {
							transform: translateY(-50%) rotate(180deg);
						}
					}
					
					
				}
				
				&.nav-button a {
					padding: $button-padding;
					border: 1px solid $kiwi;
					transition: all .25s ease-out;
					display: inline-block;
					overflow: hidden;
					position: relative;
					z-index: 0;
					background: transparent;
					&:after {
						content: '';
						position: absolute;
						z-index: -1;
						width: 0;
						height: 100%;
						top: 50%;
						left: 50%;
						background: $kiwi;
						opacity: 0;
						transform: translate(-50%, -50%);
						transition: all .3s ease;
					}
					&:hover:after {
						width: 102%;
						opacity: 1;
					}
					&:before {
						display: none !important;
					}
				}
			}

			> li.menu-item-has-children {
				position: relative;
			}
			
			> li.menu-item-has-children > ul.menu {
				display: none;
				background: rgba(24, 61, 73, 1);
				box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.17);
				border: 0;
				padding: 1.75rem 1rem;
				top: 100%;
				right: auto;
				left: 0;
				position: absolute;
				z-index: 1;
				min-width: rem-calc(200);

				> li {
					width: 100%;
					&:not(:first-child) {
						margin-top: rem-calc(15);
					}
					a {
						color: $white;
						//font-size: rem-calc(12);
						font-size: 0.95rem;
						padding: 0;
						transition: color .3s ease;
						
						&:hover {
							color: $kiwi;
						}
					}
				}
			}

			/*
			.submenu {
				background: rgba(24, 61, 73, 1);
				box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.17);
				border: 0;
				padding: 1.75rem 1rem;
				
				//z-index: -1;
				//background: rgba(24, 61, 73, 1);
				//box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.17);
				//border: 0;
				//top: rem-calc(-32) !important;
				//left: -1rem !important;
				//right: -1rem !important;
				//width: 100%;
				//padding-left: rem-calc(16);
				//padding-right: rem-calc(16);
				//padding-bottom: rem-calc(16);
				//padding-top: rem-calc(100);
				
				> li {
					&:not(:first-child) {
						margin-top: rem-calc(15);
					}
					a {
						color: $white;
						//font-size: rem-calc(12);
						font-size: 0.95rem;
						padding: 0;
						transition: color .3s ease;
						
						&:hover {
							color: $kiwi;
						}
					}
				}
				
				
				
			}


			*/




		}
	}
}

.hamburger-wrapper {
	.hamburger {
		position: relative;
		cursor: pointer;
		@include breakpoint(small only) {
			width: rem-calc(25);
			height: rem-calc(20);
		}

		@include breakpoint(medium) {
			width: rem-calc(25) * 1.25;
			height: rem-calc(20) * 1.25;
		}
		
		div {
			height: rem-calc(1);
			width: 100%;
			background: $white;
			position: absolute;
			left: 0;
			
			&.top-bun {
				top: 0;
			}
			&.patty {
				top: 50%;
				transform: translateY(-50%);
			}
			&.bottom-bun {
				bottom: 0;
			}
		}
	}
	
}

.hamburger-icon {
	cursor: pointer;
}

/*********************
New Header and Mega Menu Styles 
*********************/
.header-main {
	position: relative;
}

#main-nav {
	position: unset;
	> li {
		//position: relative;
	}
}

.main-nav-wrapper {
	display: flex !important;
	align-items: center;
}

.nav-get-started {
	padding-left: rem-calc(30);

	.button {
		border-width: rem-calc(2) !important;
		font-size: 1rem !important;
		padding-top: rem-calc(12) !important;
		padding-bottom: rem-calc(12) !important;
	}
}


.new-mega-menu-wrapper {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	transform: translateY(100%);
	background: $white;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	border: 1px solid rgba(245, 245, 245, 1);

	visibility: hidden;
	opacity: 0;
	z-index: -1;




	.tabs {
		border: none !important;

		
	}

	.mm-tab-titles {
		width: rem-calc(330);
	}

	.mm-tab-content {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: -100%;
			background-color: $light-gray;
			z-index: -1;
		}
	}

	.tabs-content {
		background-color: $light-gray;
		height: 100%;
	}

	.tabs-title {
		padding: 0 !important;

		a {
			padding: {
				top: rem-calc(25);
				bottom: rem-calc(25);
				left: rem-calc(32);
			}
			color: $dark-blue;
			font-size: rem-calc(13.6) !important; 
			span {
				position: relative;
				display: inline-block;
			}
			
			

			&[aria-selected=true] {
				background: $light-gray !important;
				color: $teal;
				font-weight: 700;

				span:after {
					opacity: 1;
					transform: translate(10px, -50%);
				}
			}

			span:after {
				content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.609' height='13.308' viewBox='0 0 7.609 13.308'%3E%3Cpath id='Icon_ionic-ios-arrow-forward' data-name='Icon ionic-ios-arrow-forward' d='M16.562,12.848,11.526,7.816a.947.947,0,0,1,0-1.343.959.959,0,0,1,1.347,0l5.705,5.7a.949.949,0,0,1,.028,1.311l-5.729,5.741a.951.951,0,0,1-1.347-1.343Z' transform='translate(-11.246 -6.196)' fill='%23007069'/%3E%3C/svg%3E%0A");
				position: absolute;
				top: 50%;
				right: rem-calc(-5);
				transform: translate(0px, -50%);
				//transform: translate(-20px, -50%);
				height: rem-calc(13.31);
				width: rem-calc(7.61);
				transition: all .2s ease-in;
				opacity: 0;
				z-index: 1;
			}
		}
	}

	.tabs-content {
		height: 100%;
		padding-left: rem-calc(35);
	}


}

.link-box-a {
	padding: 0 !important;
}

.mm-link-box {
	background: $white;
	padding: 1rem;
	transition: transform .3s ease-in-out;

	&.has-fi {
		padding-top: rem-calc(110);
		position: relative;

		.mm-link-box-title {
			padding-bottom: rem-calc(10);
		}

	}

	&:hover {
		transform: translateY(-2px);
	}

	.mm-link-box-header {
		padding-bottom: rem-calc(10);
	}

	.mm-link-box-title {
		color: $teal;
		font-size: rem-calc(13.6);
		font-weight: 700;
		line-height: 1.2;
	}

	.mm-link-box-icon {
		width: rem-calc(28);
		height: rem-calc(33);
	}

	.mm-link-box-desc {
		p {
			font-size: rem-calc(12);
			color: $gray;
		}
		
	}

	.mm-link-box-learn-more {
		display: inline-block;
		font-size: rem-calc(11.2);
		color: $blue;
		font-weight: 700;
		position: relative;
		margin-bottom: rem-calc(8);
		padding-right: rem-calc(15);

		&:after {
			content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.402' height='7.822' viewBox='0 0 9.402 7.822'%3E%3Cg id='Group_1770' data-name='Group 1770' transform='translate(0 0)'%3E%3Cpath id='Path_4232' data-name='Path 4232' d='M9.251,44.891l-3.4-3.4a.516.516,0,0,0-.728,0L4.82,41.8a.511.511,0,0,0-.15.364.521.521,0,0,0,.15.368L6.8,44.522H.508A.5.5,0,0,0,0,45.027v.436a.521.521,0,0,0,.508.528H6.823l-2,2a.51.51,0,0,0,0,.723l.308.307a.516.516,0,0,0,.728,0l3.4-3.4a.519.519,0,0,0,0-.73Z' transform='translate(0 -41.346)' fill='%23c1d831'/%3E%3C/g%3E%3C/svg%3E");
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
		}
	}

}

.other-content-wrapper {
	padding-top: rem-calc(30);

	.mm-button {
		padding-top: rem-calc(24);
	}

}

.mm-content-page-title {
	font-size: rem-calc(18);
	font-weight: 700;
	color: $blue;
	margin-bottom: rem-calc(20);


}

.mm-content-page-desc {
	p {
		font-size: rem-calc(12.8);
		color: $dark-blue;
		line-height: 1.75 !important;
	}
	

}

.mm-button {
	.button {
		font-size: rem-calc(12) !important;
		padding: rem-calc(10) rem-calc(26) !important;
	}
}



.mm-link-content {
	padding-top: rem-calc(24);
	padding-bottom: rem-calc(32);
	padding-left: rem-calc(37);

	&.result-link-content {
		padding-top: rem-calc(100);
	}
}

.mm-page-info-wrapper {
	width: rem-calc(330);
	padding-top: rem-calc(46);
	padding-bottom: rem-calc(46);
	padding-right: rem-calc(30);

	.mm-button {
		padding-top: rem-calc(15);
	}
}

.mm-link-box-featured-image {
	position: absolute;
	width: 100%;
	height: rem-calc(152);
	top: rem-calc(-64);
	left: 0;
	display: flex;
	justify-content: center;
	align-items: flex-end;

	img {
		max-height: 100%;
		width: auto !important;
	}
}

.link-box-a.pricing-link-box {
	display: inline-block !important;
	padding-top: rem-calc(15) !important; 
}

.mm-hubspot-badges {
	padding-left: 10%;
	padding-top: rem-calc(20);
}

.hs-badges {
	padding-top: rem-calc(20);
}



/******************
Offcanvas Styles
-- new styles added
******************/

.off-canvas {
	padding-bottom: rem-calc(150);

}

.new-off-canvas-inner {
	
	
	

	.off-canvas-content-wrapper {
		padding-top: rem-calc(40);
		padding-left: rem-calc(27);
		padding-right: rem-calc(27);
		
		
		
	}

	#offcanvas-nav {
		padding-top: rem-calc(48);
		> li {
			padding: {
				left: 0;
				right: 0;
				top: rem-calc(12);
				bottom: rem-calc(12);
			}
			border-bottom: 1px solid rgba($white, .25);
			
			&.menu-item-has-children {
				> a {
					padding-right: rem-calc(20);
					position: relative;
					&:after {
						
						position: absolute;
						right: 0;
						
					}
					
				}
				&[aria-expanded="false"] {
					> a:after {
						content: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226.168%22%20height%3D%2210.787%22%20viewBox%3D%220%200%206.168%2010.787%22%3E%20%3Cpath%20id%3D%22Icon_ionic-ios-arrow-down%22%20data-name%3D%22Icon%20ionic-ios-arrow-down%22%20d%3D%22M11.583%2C15.555l4.079-4.082a.768.768%2C0%2C0%2C1%2C1.089%2C0%2C.777.777%2C0%2C0%2C1%2C0%2C1.092L12.129%2C17.19a.769.769%2C0%2C0%2C1-1.063.022L6.412%2C12.568A.771.771%2C0%2C0%2C1%2C7.5%2C11.476Z%22%20transform%3D%22translate(-11.246%2016.975)%20rotate(-90)%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fsvg%3E');
						height: rem-calc(11);
						width: rem-calc(6);
						top: 20%;
						transform: translateY(-50%);
					}
				}
				&[aria-expanded="true"] {
					> a:after {
						content: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210.787%22%20height%3D%226.168%22%20viewBox%3D%220%200%2010.787%206.168%22%3E%20%3Cpath%20id%3D%22Icon_ionic-ios-arrow-down%22%20data-name%3D%22Icon%20ionic-ios-arrow-down%22%20d%3D%22M11.583%2C15.555l4.079-4.082a.768.768%2C0%2C0%2C1%2C1.089%2C0%2C.777.777%2C0%2C0%2C1%2C0%2C1.092L12.129%2C17.19a.769.769%2C0%2C0%2C1-1.063.022L6.412%2C12.568A.771.771%2C0%2C0%2C1%2C7.5%2C11.476Z%22%20transform%3D%22translate(-6.188%20-11.246)%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fsvg%3E');
						height: rem-calc(6);
						width: rem-calc(11);
						top: 0;
						transform: translateY(-50%);
					}
				}
			}

			> a {
				font-size: rem-calc(20);
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: rem-calc(0.8);
				color: $white;
				padding: 0;
				display: inline-block;
			}
		}

		.submenu {
			padding-left: rem-calc(20);
			li {
				padding-top: rem-calc(10);
				padding-bottom: rem-calc(10);
				a {
					color: $kiwi;
					font-size: rem-calc(16);
					display: inline-block;
					padding: 0 rem-calc(14) 0 0;
					position: relative;
					line-height: 1;
					&:after {
						content: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20id%3D%22Group_2000%22%20data-name%3D%22Group%202000%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.095%22%20height%3D%227.566%22%20viewBox%3D%220%200%209.095%207.566%22%3E%20%3Cpath%20id%3D%22Path_4232%22%20data-name%3D%22Path%204232%22%20d%3D%22M8.949%2C44.776%2C5.665%2C41.491a.5.5%2C0%2C0%2C0-.7%2C0l-.3.3a.494.494%2C0%2C0%2C0-.145.352.5.5%2C0%2C0%2C0%2C.145.356l1.916%2C1.92H.491A.486.486%2C0%2C0%2C0%2C0%2C44.907v.422a.5.5%2C0%2C0%2C0%2C.491.511H6.6L4.663%2C47.771a.493.493%2C0%2C0%2C0%2C0%2C.7l.3.3a.5.5%2C0%2C0%2C0%2C.7%2C0L8.95%2C45.482a.5.5%2C0%2C0%2C0%2C0-.707Z%22%20transform%3D%22translate(0%20-41.346)%22%20fill%3D%22%23c1d831%22%2F%3E%20%3C%2Fsvg%3E');
						position: absolute;
						top: 20%;
						right: 0;
						width: rem-calc(9);
						height: rem-calc(8);
						transform: translateY(-50%);
					}
				}
			}
		}


	}

	.offcanvas-bottom {
		padding-top: rem-calc(40);
	}
}

.offcanvas-bottom-graphic {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: auto;
	z-index: -1;
	
	

	img {
		max-width: 100%;
		width: 100%;
		height: auto;
	}
}

/*
.off-canvas {
	
	
	.off-canvas-close {
		position: absolute;
		top: rem-calc(20);
		left: rem-calc(35);
		cursor: pointer;
		appearance: none;
		-webkit-appearance: none;
		line-height: 1;
		
		&:focus-visible {
			outline: none;
		}
		
		.close-x {
			font-weight: 700;
			font-size: rem-calc(16);
			color: $kiwi;
			line-height: 1;
		}
		.close-text {
			font-weight: 600;
			font-size: rem-calc(13);
			color: $white;
			text-transform: uppercase;
			line-height: 1;
			margin-left: rem-calc(10);
		}
	}
	
	.off-canvas-inner {
		padding: rem-calc(90) rem-calc(0) rem-calc(45) rem-calc(0);
	}
	
	#offcanvas-nav {
		> li {
			padding: {
				left: rem-calc(32);
				right: rem-calc(32);
			}
			&:not(:first-child) {
				border-top: 1px solid rgba($white, .25);
			}
			&.nav-button {
				a {
					color: $kiwi;
				}
			}
			&.active, &.is-active {
				> a {
					background: none !important;
				}
			}
			&.is-accordion-submenu-parent {
				> a {
					&:after {
						content: '';
						position: absolute;
						top: 50%;
						right: 0;
						margin: 0 !important;
						height: rem-calc(5.55) * 1.20;
						width: rem-calc(7.46) * 1.20;
						transform: translateY(-50%) rotate(0);
						transform-origin: center;
						background: {
							image: url(../images/svg/menu-arrow-down.svg);
							size: contain;
							position: center center;
							repeat: no-repeat;
						} 
					}
				}
			}
			
			&[aria-expanded=true] > a {
						
				&:after {
					transform: translateY(-50%) rotate(180deg);
				}
			}
			
			> a {
				color: $white;
				text-transform: uppercase;
				padding: rem-calc(15) 0;
				position: relative;
				font: {
					size: rem-calc(14);
					weight: 600;
				}
				
			}
		}
		
		.submenu {
			padding: {
				left: rem-calc(15);
				bottom: rem-calc(18);
				top: rem-calc(3);
			}
			li {
				&:not(:first-child) {
					margin-top: rem-calc(15);
				}
				
				a {
					padding: 0;
					color: $white;
					font-size: rem-calc(14);
				}
			}
		}
	}
	
	.offcanvas-bottom {
		margin-top: 5rem;
		#top-links {
			justify-content: center;
			li {
				width: 100%;
				padding-left: 2rem;
				padding-right: 2rem;
				
				a {
					color: $white;
					//text-transform: uppercase;
					padding: rem-calc(12) 0;
					font: {
						size: rem-calc(12);
						weight: 400;
					}
				}
			}
		}
	}
}
*/
/********************
Mega Menu
********************/
/*
$mm-bg: rgba(248, 245, 244, 1) !important;

#main-nav {
	//position: relative !important;
	
	.mega-menu-wrapper {
		
		position: absolute;
		right: 0;
		left: rem-calc(-200);

		.mega-menu {
			//padding-left: 15% !important;
			display: none;
			padding-top: rem-calc(24);
			
		}
	}

	.tabs {
		border: none !important;
	}

	.tabs-title {
		padding-right: rem-calc(8);
		padding-top: rem-calc(6);
		padding-bottom: rem-calc(6);
		a {
			
			color: $dark-blue;
			position: relative;
			

			&[aria-selected=true] {
				background: $mm-bg;
				color: $teal;
				text-decoration: underline;
				font-weight: 700;

				&:after {
					opacity: 1;
					transform: translate(0, -50%);
				}
			}

			&:after {
				content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.609' height='13.308' viewBox='0 0 7.609 13.308'%3E%3Cpath id='Icon_ionic-ios-arrow-forward' data-name='Icon ionic-ios-arrow-forward' d='M16.562,12.848,11.526,7.816a.947.947,0,0,1,0-1.343.959.959,0,0,1,1.347,0l5.705,5.7a.949.949,0,0,1,.028,1.311l-5.729,5.741a.951.951,0,0,1-1.347-1.343Z' transform='translate(-11.246 -6.196)' fill='%23007069'/%3E%3C/svg%3E%0A");
				position: absolute;
				top: 50%;
				right: rem-calc(22);
				transform: translate(-20px, -50%);
				height: rem-calc(13.31);
				width: rem-calc(7.61);
				transition: all .2s ease-in;
				opacity: 0;
				z-index: 1;
			}
		}
	}

	.tabs-content {
		height: 100%;
	}

	.mm-grid {
		background: $white;
		padding: rem-calc(8);
		box-shadow: 2px 2px 2px rgba(0, 0, 0, .1), -2px 2px 2px rgba(0, 0, 0, .1), 0px 2px 2px rgba(0, 0, 0, .1);
	}

	.mm-tabs-content-cell {
		.tabs-content {
			background: $mm-bg;
			border: none !important;
		}
	}

	.mm-tabs-content-cell {
		font-size: rem-calc(12);

		.page-link-title {
			color: $dark-blue;
			font-weight: 700;
			font-size: rem-calc(14);
			margin-bottom: rem-calc(20);
		}

		.page-link-desc {
			color: $dark-blue;
			line-height: 2;
		}

		.button {
			background: $blue;
			color: $white;
			font-size: rem-calc(12) !important;
			padding: rem-calc(10) rem-calc(26);
			margin-top: 2rem;
			position: relative;

			&:after {
				content: '';
				position: absolute;
				z-index: -1;
				width: 0;
				height: 100%;
				top: 50%;
				left: 50%;
				background: $mm-bg;
				opacity: 0;
				transform: translate(-50%, -50%);
				transition: all .3s ease;
			}
			&:hover{
				color: $blue;
				
				
				&:after {
					width: 102%;
					opacity: 1;
				}
			}
		}
	}

	.tab-item-desc-wrapper {
		padding-right: 16%;
		padding-left: 8%;
		.button {
			display: inline-block !important;
		}
	}

	.item-children {
		.item-child-link {
			padding: 0 !important;
			display: block;
			&:not(:first-child) {
				margin-top: rem-calc(15);
			}
		}
		.item-child {
			background: $white;
			padding: rem-calc(17) rem-calc(25);
			transition: all .2s ease-in-out;


			&:hover {
				transform: translateX(3px);
				.item-child-title {
					text-decoration: underline;
					&:after {
						opacity: 1;
					}
				}
			}
			
			.item-child-title {
				position: relative;
				color: $teal;
				font-weight: 700;
				margin-bottom: rem-calc(16);
				
				&:after {
					content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.609' height='13.308' viewBox='0 0 7.609 13.308'%3E%3Cpath id='Icon_ionic-ios-arrow-forward' data-name='Icon ionic-ios-arrow-forward' d='M16.562,12.848,11.526,7.816a.947.947,0,0,1,0-1.343.959.959,0,0,1,1.347,0l5.705,5.7a.949.949,0,0,1,.028,1.311l-5.729,5.741a.951.951,0,0,1-1.347-1.343Z' transform='translate(-11.246 -6.196)' fill='%23007069'/%3E%3C/svg%3E%0A");
					position: absolute;
					top: 50%;
					right: rem-calc(-16);
					transform: translateY(-50%);
					height: rem-calc(13.31);
					width: rem-calc(7.61);
					transition: all .2s ease-in;
					opacity: 0;
					z-index: 1;
				}
			}

			.item-child-desc {
				line-height: 2;
				color: $dark-blue;
			}
		}
	}
}
*/
/* Banner Styles */
/*
:root {
	--afterWidth:0px;
}
*/

.sub-page-banner {
	background: rgb(0,112,105); /* Old browsers */
	background: -moz-linear-gradient(-71deg, rgba(0,112,105,1) 0%, rgba(20,65,88,1) 92%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-71deg, rgba(0,112,105,1) 0%,rgba(20,65,88,1) 92%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(109deg, rgba(0,112,105,1) 0%,rgba(20,65,88,1) 92%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007069', endColorstr='#144158',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */	
	
	
	margin-bottom: 2rem;
	
	
	
	.sub-page-banner-inner {
		position: relative;
		background: {
			image: url(../images/svg/TechPattern.svg);
			position: right bottom;
			repeat: no-repeat;
		}
		
		@include breakpoint(small only) {
			background-size: rem-calc(230);
		}
		
		
		@include breakpoint(medium) {
			background-size: rem-calc(610);
		}
		
		h1 {
			color: $white;
			font-weight: 700;
			position: relative;
			margin: 0;
			
			
			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				height: rem-calc(4);
				background-color: $kiwi;
				transform: translateY(2px);
			}
			@include breakpoint(small only) {
				font-size: rem-calc(32);
				padding-bottom: rem-calc(12);
				&:after {
					width: rem-calc(80);
				}
			}
			@include breakpoint(medium) {
				&:after {
					//width: var(--afterWidth);
				}
			}
			@include breakpoint(medium only) {
				font-size: rem-calc(54);
				padding-bottom: rem-calc(16);
			}
			@include breakpoint(large) {
				font-size: rem-calc(64);
				padding-bottom: rem-calc(20);
			}
		}
		
		.banner-curve {
			position: absolute;
			bottom: -10px;
			left: 0;
			width: 100%;
			line-height: 1;
			
			svg {
				path, .cls-1 {
					fill: $white;
					opacity: 1;
					stroke: transparent;
				}
			}
		}
		
		
	}
}


.new-header-bg-medium {
	position: absolute;
	top: 0%;
	left: 50%;
	width: rem-calc(1200);
	z-index: -1;
	transform: translateX(-50%);

	img {
		width: 100%;
		height: auto;
	}
}

.new-header-bg-small {
	position: absolute;
	top: rem-calc(-20);
	left: 50%;
	width: rem-calc(700);
	z-index: -1;
	transform: translateX(-50%);

	img {
		width: 100% !important;
		height: rem-calc(164);
	}
}

/***************************
New Home page Banner Styles
***************************/

.home-page-banner-new-inner {
	position: relative;


	@include breakpoint(medium only) {
		padding-bottom: rem-calc(40) !important;
	}
	@include breakpoint(1249px down) {
		padding-top: rem-calc(150);
		padding-bottom: rem-calc(112);
	}
	@include breakpoint(1250px) {
		padding-top: rem-calc(225);
		padding-bottom: rem-calc(192);
	}

	.hpbn-bg-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;

		.grid-x {
			position: relative;
		}

		.hpbn-bg {
			position: absolute;
			top: 0;
			left: rem-calc(320);
			z-index: -1;
			width: 150%
		}

		.awards-svg {
			position: absolute;
			top: 20%;
			left: 37%;
			z-index: 1;
		}
	}
}

.hpbn-badges {
	position: absolute;
	z-index: 0;
	top: 27%;
	left: 40%;
	width: 11%;
	height: 35%;

	
	.hpbn-badge {
		position: absolute;
		opacity: 0;
		img {
			height: auto;
			
		}
	}
	

	#logo-hs-platinum {
		//width: rem-calc(196);
		width: 93.83378%;
		z-index: 3;
		top: 0;
		left: 0;
	}

	#logo-google-partner {
		//width: rem-calc(103);
		width: 49.31%;
		z-index: 1;
		top: 44%;
		right: -14%;
	}

	#logo-databox-partner {
		//width: rem-calc(69);
		width: 33.0333%;
		z-index: 2;
		bottom: 7%;
		left: 50%;
	}

}

#hh-plus-top {
	position: absolute;
	top: 17%;
	left: 26%;
	opacity: 0;
}

#hh-plus-bottom {
	position: absolute;
	bottom: 21%;
	left: 47%;
	opacity: 0;
}

#hh-circle {
	position: absolute;
	top: 20%;
	left: 23.5%;
	opacity: 0;
}


.display-block {
	display: block;
	
}

.nhpb-banner-content {
	@include breakpoint(small only) {
		padding-top: rem-calc(50) !important;
	}
	@include breakpoint(large) {
		width: rem-calc(527.7);
		
	}
	@include breakpoint(1249px down) {
		padding-top: rem-calc(100);
	}
	@include breakpoint(1250px) {
		padding-top: rem-calc(24);
	}
	h1 {
		color: $dark-blue;
		
		font-weight: 600;
		@include breakpoint(small only) {
			font-size: rem-calc(31);
		}
		@include breakpoint(medium) {
			font-size: rem-calc(52);
		}

	}
	.h-award {
		display: inline-block;
		background: $dark-blue;
		color: $white;
		text-transform: uppercase;
		
		font-weight: 500;
		letter-spacing: 2.8px;
		
		margin-bottom: rem-calc(12);
		@include breakpoint(small only) {
			font-size: rem-calc(10);
			padding: rem-calc(8) rem-calc(15) rem-calc(6) rem-calc(15);
		}
		@include breakpoint(medium) {
			font-size: rem-calc(16);
			padding: rem-calc(13) rem-calc(24);
		}
	}
	.h-for {
		display: inline-block;
		position: relative;
		
		font-weight: 500;
		font-style: italic;
		color: $teal;
		
		@include breakpoint(small only) {
			font-size: rem-calc(17);
		}
		@include breakpoint(medium) {
			font-size: rem-calc(30);
		}
	}

	.h-kiwi {
		display: block;
		color: $kiwi;
		
		font-weight: 700;
		@include breakpoint(small only) {
			font-size: rem-calc(35);
		}
		@include breakpoint(medium) {
			font-size: rem-calc(60);
		}
	}

	.nhpb-banner-text {
		@include breakpoint(large) {
			padding-right: 6%;
		}
		p {
			
			@include breakpoint(small only) {
				font-size: rem-calc(16);
			}
			@include breakpoint(medium) {
				font-size: rem-calc(19.2);
			}
		}
	}

	.button {
		margin-top: rem-calc(25);
	}
}

.nhpb-banner-image-medium-down {
	@include breakpoint(small only) {
		img {
			
			transform: scale(1.35) translateX(-2%);
			padding-top: rem-calc(100);
		}
	}
	@include breakpoint(medium only) {
		padding-top: rem-calc(64);
	}
}




/***************************
New Sub Page Banner Styles
***************************/

.interior-page-banner-inner {
	position: relative;

	@include breakpoint(small only) {
		&.ipb-v2 {
			padding-top: rem-calc(180) !important;
			padding-bottom: 0 !important;
		}
	}

	@include breakpoint(medium only) {
		&.ipb-v2 {
			padding-top: rem-calc(220) !important;
			padding-bottom: 0 !important;
		}
	}

	@include breakpoint(1249px down) {
		&:not(.ipb-v2) {
			padding-top: rem-calc(150);
			padding-bottom: rem-calc(112);
		}
		&.ipb-v2 {
			padding-top: rem-calc(250);
			padding-bottom: 0;
		}
		
	}
	@include breakpoint(1250px) {
		&:not(.ipb-v2) {
			padding-top: rem-calc(225);
			padding-bottom: rem-calc(112);
		}
		&.ipb-v2 {
			padding-top: rem-calc(225);
			padding-bottom: 0;
		}
	}

	.ipb-bg-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;

		.grid-x {
			position: relative;
		}

		.ipb-bg {
			position: absolute;
			top: 0;
			left: rem-calc(240);
			z-index: -1;
		}
	}

	
}

.nipb-grid {
	position: relative;
	@include breakpoint(small only) {
		padding-top: rem-calc(50) !important;
	}
	@include breakpoint(medium only) {
		padding-top: rem-calc(180) !important;
		justify-content: center !important;
	}
}

.nipb-banner-content {

	&.no-padding {
		padding-top: 0 !important;
	}
	@include breakpoint(medium only) {
		padding-top: rem-calc(64);
	}
	@include breakpoint(large) {
		padding-top: rem-calc(114);
		//padding-bottom: rem-calc(114);
	}

	h1 {
		@include breakpoint(medium) {
			font-size: rem-calc(46);
		}
		
		font-weight: 600;
	}

	.nipb-banner-text {
		p {
			
			font-weight: 400;
			color: $gray;
			line-height: 1.6 !important;
			/*
			@include breakpoint(medium only) {
				font-size: rem-calc(21);
			}
			@include breakpoint(large) {
				font-size: rem-calc(28);
			}
			*/
		}

		ul, ol {
			li {
				&:not(:first-child) {
					padding-top: rem-calc(8);
				}
			}
		}
	}

	.button {
		margin-top: rem-calc(24);
	}
}

.single-portfolio .nipb-banner-text {
	p {
			
		font-weight: 300;
		color: $gray;
		line-height: 1.4 !important;
		
		@include breakpoint(medium only) {
			font-size: rem-calc(21);
		}
		@include breakpoint(large) {
			font-size: rem-calc(28);
		}
		
	}
}

.pipb-breadcrumbs {
	
	

	@include breakpoint(medium) {
		position: absolute;
		left: rem-calc(15);
		z-index: 1;
		display: block;
	}
	@include breakpoint(medium only) {
		top: rem-calc(80);
	}

	@include breakpoint(large) {
		top: 0;
		
	}
}



.rm-breadcrumbs {
	@include breakpoint(medium down) {
		margin-bottom: rem-calc(32);
	}
	display: inline-block;
	nav {
		background: $light-gray;
		
		@include breakpoint(small only) {
			padding: rem-calc(6) rem-calc(12);
		}
		@include breakpoint(medium) {
			padding: rem-calc(12) rem-calc(24);
		}

		p {
			margin: 0 !important;
		}

		a {
			@include breakpoint(small only) {
				font-size: rem-calc(12);
			}
			@include breakpoint(medium) {
				font-size: rem-calc(15);
			}
			
			color: $gray;
			font-weight: 500;
			text-decoration: underline;
		}

		.last {
			@include breakpoint(small only) {
				font-size: rem-calc(12);
			}
			@include breakpoint(medium) {
				font-size: rem-calc(15);
			}
			color: $gray;
			font-weight: 600;
		}

		.separator {
			display: inline-block;
			margin-left: rem-calc(12);
			margin-right: rem-calc(12);
			height: rem-calc(13);
			width: rem-calc(7);
			background: {
				image: url('../images/svg/icon-breadcrumbs-arrow-green.svg');
				position: center center;
				size: contain;
				repeat: no-repeat;
			}
		}
	}
}

.nipb-banner-image {
	@include breakpoint(small only) {
		padding-top: rem-calc(50);
		padding-bottom: rem-calc(40);
	}
	@include breakpoint(medium) {
		text-align: right;
	}
}


/************************
Portfolio Single Styles
************************/
.single-portfolio {
	.inner-content {
		padding-bottom: 2rem;
	}
}
.project-short-description {
	@include breakpoint(large) {
		font-size: rem-calc(22);
		line-height: 1.5;
	}
}

.project-images {
	padding-top: 4rem;
	padding-bottom: 4rem;
	
	.orbit-figure > img {
		height: 100%;
		width: auto;
		@include breakpoint(large) {
			max-height: rem-calc(575);
		}
	}
}

.project-main-column {
	@include breakpoint(large) {
		padding-right: 2rem;
	}
}

.project-description {
	.project-description-item {
		&:not(:first-child) {
			margin-top: rem-calc(50);
		}
		&:last-child {
			margin-bottom: rem-calc(40);
		}
	}
}

.project-testimonial {
	position: relative;
	padding-top: rem-calc(66);
	padding-bottom: rem-calc(66);
	z-index: 0;
	
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: -80px;
		z-index: -1;
		background: {
			image: url(../images/svg/graphic-homepage-quotation-mark-grey.svg);
			size: contain;
			position: center center;
			repeat: no-repeat;
		}
		
		@include breakpoint(large) {
			height: rem-calc(246.41);
			width: rem-calc(313.21);
		}
	}
	
	.project-testimonial-heading {
		padding-bottom: rem-calc(45);
		h2 {
			color: $kiwi;
			margin: 0;
		}
	}
	
	.quote-author-photo {
		img {
			border-radius: 50%;
		}
		@include breakpoint(large) {
			width: rem-calc(122);
		}
	}
	
	.quote-byline {
		margin-top: rem-calc(42);
		color: $blue;
		font-size: rem-calc(20);
		text-transform: uppercase;
		.name-title {
			font-weight: 500;
		}
		.company {
			font-weight: 700;
		}
	}
}

.portfolio-sidebar {
	@include breakpoint(small only) {
		margin-top: 4rem;
	}
	.portfolio-sidebar-item {
		margin-bottom: rem-calc(52);
	}
	
	.portfolio-sidebar-item-heading {
		h3 {
			color: $blue;
		}
	}
	
	ul {
		li {
			padding: {
				top: rem-calc(18);
				bottom: rem-calc(18);
			}
			border-bottom: 2px solid rgba($gray, .22);
			font-size: rem-calc(18);
			
			&:first-child {
				border-top: 2px solid rgba($gray, .22);
			}
			
			
		}
	}
	
	.services {
		ul {
			li {
				position: relative;
				color: rgb(219, 219, 219);
				padding: {
					left:  rem-calc(28);
					right:  rem-calc(28);
				}
				
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					width: rem-calc(20.22);
					height: rem-calc(19.72);
					transform: translateY(-50%);
					background: {
						position: center center;
						size: contain;
						repeat: no-repeat;
						image: url(../images/svg/check-gray.svg);
					}
				}
			}
		}
		&.branding {
			li.branding {
				color: $gray;
				&:before {
					background-image: url(../images/svg/check-kiwi.svg);
				}
			}
		}
		&.digital-marketing {
			li.digital-marketing {
				color: $gray;
				&:before {
					background-image: url(../images/svg/check-kiwi.svg);
				}
			}
		}
		&.websites {
			li.websites {
				color: $gray;
				&:before {
					background-image: url(../images/svg/check-kiwi.svg);
				}
			}
		}
		&.sales-enablement {
			li.sales-enablement {
				color: $gray;
				&:before {
					background-image: url(../images/svg/check-kiwi.svg);
				}
			}
		}
		&.hubspot {
			li.hubspot {
				color: $gray;
				&:before {
					background-image: url(../images/svg/check-kiwi.svg);
				}
			}
		}
	}
	
	.project-cta {
		background: transparent linear-gradient(180deg, rgba(28, 71, 94, 1) 0%, rgba(6, 160, 151, 1) 100%) 0% 0% no-repeat padding-box;
		padding: 1rem;
		
		.project-cta-inner {
			background: $white;
			padding: rem-calc(24);
			
			.project-cta-graphic {
				margin-bottom: rem-calc(20);
			}
			
			h4 {
				font-size: rem-calc(20);
				margin-bottom: rem-calc(40);
			}
			
			/*
			.button {
				font-size: rem-calc(12);
				padding: rem-calc(12) rem-calc(20);
			}
			*/
		}
	}
}

/* Portfolio Grid Styles */
.portfolio-grid {
	.portfolio-item {
		padding: rem-calc(4) !important;
	}
	
	.portfolio-tile {
		position: relative;
		overflow: hidden;
		height: 100%;
		
		img {
			width: 100%;
			height: auto;
		}
	}
	
	.portfolio-tile-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($blue, .9);
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
		transition: opacity .2s linear;
		opacity: 1;
		padding: 11.628%;
		h3 {
			color: $kiwi;
			text-transform: uppercase;
			font-weight: 700;
			
			@include breakpoint(small only) {
				font-size: rem-calc(17);
			}
			@include breakpoint(medium ) {
				font-size: rem-calc(20);
			}
			margin-bottom: 1rem;
		}
		.tile-text {
			color: $white;
			line-height: 1.3;
			font-weight: 500;
			@include breakpoint(small only) {
				font-size: rem-calc(21);
			}
			@include breakpoint(medium ) {
				font-size: rem-calc(25);
			}
			
			
		}
		
	}
	
	.kiwi-plus-circle {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 28.372%;
		width: 28.372%;
		background: {
			image: url(../images/svg/graphic-our-work-view-more-button-green.svg);
			size: contain;
			position: center center;
			repeat: no-repeat;
		}
		transition: all .2s linear;
		transform: translate(-50%, -50%) scale3d(0, 0, 0);
	}
	
	.portfolio-tile:hover {
		.portfolio-tile-overlay {
			opacity: 0;
		}
		.kiwi-plus-circle {
			transform: translate(-50%, -50%) scale3d(1, 1, 1);
		}
	}
	
	.filter-form-wrapper {
		
		margin-top: rem-calc(32);
		margin-bottom: rem-calc(50);
		
		@include breakpoint(medium) {
			display: flex;
			flex-flow: row no-wrap;
			align-items: center;
		}
		
		.filter-label {
			color: $blue;
			line-height: 1;
			padding-right: rem-calc(32);
			text-transform: uppercase;
			font-weight: 700;
				
			
			@include breakpoint(small only) {
				font-size: rem-calc(15);
				width: 100%;
			}
			
			@include breakpoint(medium) {
				font-size: rem-calc(18);
			}
		}
		
		form {
			ul {
				margin: 0;
				padding-left: 0 !important;
				display: flex;
				flex-flow: row nowrap;
				
				li {
					padding: {
						top: 0;
						bottom: 0;
					}
					
					&:not(:first-child) {
						padding-left: rem-calc(18);
					}
					&:not(:last-child) {
						padding-right: rem-calc(18);
					}
					
					select {
						margin-bottom: 0;
						
						option:checked {
							color: $blue !important;
							font: {
								weight: 700 !important;
								size: rem-calc(18) !important;
							}
						}
					}
				}
			}
		}
	}
}

/* Filter Forms */
.filter-form-wrapper {
	
	margin-top: rem-calc(68);
	margin-bottom: rem-calc(50);
	
	@include breakpoint(medium) {
		display: flex;
		flex-flow: row no-wrap;
		align-items: center;
	}

	.filter-label {
		color: $blue;
		line-height: 1;
		padding-right: rem-calc(32);
		text-transform: uppercase;
		font: {
			weight: 700;
			size: rem-calc(18);

		}
		
		@include breakpoint(small only) {
			margin-bottom: 1rem;
		}
	}

	form.searchandfilter {
		ul {
			padding-left: 0 !important;
			margin: 0;
			display: flex;
			flex-flow: row nowrap;
			
			@include breakpoint(small only) {
				margin: {
					left: rem-calc(-15);
					right: rem-calc(-15);
				}
			}
			
			@include breakpoint(medium) {
				
			}

			li {
				padding: {
					top: 0;
					bottom: 0;
				}
				@include breakpoint(small only) {
					padding: {
						left: rem-calc(15);
						right: rem-calc(15);
					}
					width: 50%;
					label {
						display: block;
					}
					select.sf-input-select {
						min-width: auto !important;
						width: 100%;
					}
				}
				@include breakpoint(medium) {
					&:not(:first-child) {
						padding-left: rem-calc(18);
					}
					&:not(:last-child) {
						padding-right: rem-calc(18);
					}
				}
				

				select {
					margin-bottom: 0;

					option:checked {
						color: $blue !important;
						font: {
							weight: 700 !important;
							size: rem-calc(18) !important;
						}
					}
				}
			}
		}
	}
}

/* Portfolio Callout */

.portfolio-callout {
	@include breakpoint(small only) {
		.projects-wrapper .projects-inner .grid-x .cell:not(:first-child) {
			margin-top: 3rem;
		}
	}
	.pc-heading {
		padding-bottom: rem-calc(50);
	}
	.project-box {
		position: relative;
		z-index: 0;
		padding: 0 rem-calc(32) rem-calc(50) rem-calc(32);
		
		&:before {
			content: '';
			position: absolute;
			top: rem-calc(75);
			left: 0;
			right: 0;
			bottom: 0;
			background: $white;
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
			z-index: -1;
		}
	}
	.project-image {
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-end;
		justify-content: center;
		margin-bottom: rem-calc(42);
	}
	.project-title {
		position: relative;
		padding-bottom: rem-calc(32);
		margin-bottom: rem-calc(20);
		h3 {
			margin: 0 !important;
			color: $blue;
			
			@include breakpoint(medium only) {
				font-size: rem-calc(24);
			}
		}
		
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			height: rem-calc(1);
			width: rem-calc(190);
			background: $kiwi;
			transform: translateX(-50%);
		}
	}
	
	.project-value {
		line-height: 1;
		color: $kiwi;
		
		font-weight: 600;
		
		@include breakpoint(small only) {
			font-size: rem-calc(64);
		}
		@include breakpoint(medium only) {
			font-size: rem-calc(64);
		}
		@include breakpoint(large) {
			font-size: rem-calc(77);
		}
	}
	
	.project-label {
		color: $gray;
		padding-bottom: rem-calc(32);
	}
}

/* Carousel Block */
.kiwi-slider-block {
	.carousel-heading {
		@include breakpoint(small only) {
			padding-bottom: rem-calc(30);
		}
		@include breakpoint(medium only) {
			padding-bottom: rem-calc(50);
		}
		@include breakpoint(large) {
			padding-bottom: rem-calc(72);
		}
		
		
		h2 {
			margin-bottom: rem-calc(24);
		}
		
		p {
			
			&:last-child {
				margin-bottom: 0;
			}
			
			@include breakpoint(small only) {
				font-size: rem-calc(16);
			}
			@include breakpoint(medium only) {
				font-size: rem-calc(21);
			}
			@include breakpoint(large) {
				font-size: rem-calc(24);
			}
		}
	}
	
	.kiwi-slider {
		
		.slide-inner {
			margin: 0 rem-calc(10);
		}
		
	}
	
	.slick-arrow {
		&:hover {
			cursor: pointer;
		}
	}
}

/* Team Grid */
.team-grid, .featured-team-members, .join-the-team {
	@include breakpoint(small only) {
		.grid-x {
			.cell:not(:first-child) {
				margin-top: 2rem;
			}
		}
		
		.team-member-bottom {
			padding-top: rem-calc(15) !important;
		}
	}
	
	.team-members {
		@include breakpoint(small only) {
			padding-bottom: rem-calc(69);
		}
		@include breakpoint(medium) {
			padding-bottom: rem-calc(84);
		}
	}
	
	.featured-team-heading {
		padding-bottom: rem-calc(50);
		p {
			font-size: rem-calc(24);
		}
	}
	.team-member-top {
		position: relative;
		overflow: hidden;
		z-index: 0;
		&:hover {
			.member-bio {
				opacity: 1;
			}
		}
		
		.member-photo {
			img {
				width: 100%;
				max-width: none !important;
				height: auto;
			}
			
		}
	}
	.member-bio {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		background: rgba($blue, .9);
		padding: 6%;
		color: $white;
		z-index: 1;
		opacity: 0;
		transition: opacity .3s ease;
		
		
		@include breakpoint(small only) {
			font-size: rem-calc(14);
		}
		@include breakpoint(medium) {
			font-size: rem-calc(15);
		}
	}
	
	.team-member-bottom {
		padding: rem-calc(30) rem-calc(15);
		
		.member-name {
			color: $blue;
			padding-bottom: rem-calc(5);
			font: {
				weight: 600;
				size: rem-calc(20);
			}
		}
		
		
	}
	
	.meet-team {
		padding-top: 1rem;
	}
	
	.join-team-cta {
		//margin-top: rem-calc(100);
		background: transparent linear-gradient(180deg, rgba(28, 71, 94, 1) 0%, rgba(6, 160, 151, 1) 100%) 0% 0% no-repeat padding-box;
		padding: rem-calc(16);
	}
	
	.join-team-cta-inner {
		background: $white;
		
		@include breakpoint(small only) {
			padding: rem-calc(24);
		}
		@include breakpoint(medium only) {
			padding: rem-calc(40);
		}
		@include breakpoint(large) {
			padding: rem-calc(50);
		}
		
		h3 {
			margin-bottom: rem-calc(24);
		}
		
		.button {
			margin-top: rem-calc(24);
		}
	}
	
	.jt-icon-wrapper {
		
		svg {
			max-width: 100%;
			height: auto;
		}
		
		@include breakpoint(small only) {
			width: 50%;
			margin: 0 auto;
		}
		@include breakpoint(medium only) {
			max-width: rem-calc(150);
			margin-right: rem-calc(36);
		}
		@include breakpoint(large) {
			max-width: rem-calc(173);
			margin-right: rem-calc(50);
		}
	}
}

@include breakpoint(medium) {
	.team-grid .team-member {
		transform: translateY(200px);
		opacity: 0;
	}	
}


/* Join the team CTA */



/* Core Value Coasters */
.core-values {
	
	.core-values-heading {
		@include breakpoint(small only) {
			padding-bottom: rem-calc(40);

			p {
				font-size: rem-calc(16);
			}
		}
		@include breakpoint(medium only) {
			padding-bottom: rem-calc(50);

			p {
				font-size: rem-calc(21);
			}
		}
		@include breakpoint(large) {
			padding-bottom: rem-calc(64);

			p {
				font-size: rem-calc(24);
			}
		}
		
	}
	.coaster {
		position: relative;
		&:hover {
			.coaster-text {
				opacity: 1;
			}
		}
	}
	
	.coaster-text {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		background: transparent linear-gradient(135deg, rgba(0, 112, 105, 1) 0%, rgba(20, 65, 88, 1) 100%) 0% 0% no-repeat padding-box;
		color: $white;
		transition: opacity .3s ease;
		
		text-align: center;
		
		@include breakpoint(small only) {
			padding: 10%;
			font-size: rem-calc(13);
		}
		
		@include breakpoint(medium only) {
			padding: 20%;
		}
		
		@include breakpoint(large) {
			padding: 25%;
		}
	}
}

/* Bragging Rights */

.aaa {
	.aaa-heading {
		padding-bottom: rem-calc(50);
		h2 {
			margin-bottom: 0;
		}
	}
	
	@include breakpoint(small only) {
		.logo-wrapper {
			padding: {
				left: rem-calc(10);
				right: rem-calc(10);
			}
		}
	}
}

/* Events */

.page-template-template-events, .recent-events {
	h2 {
		margin-bottom: rem-calc(75);
	}
	.event-wrapper {
		margin-bottom: rem-calc(75);
		
		img {
			margin-bottom: rem-calc(50);
		}
		
		h3 {
			margin-bottom: rem-calc(30);
			
			a {
				color: $blue;
				text-decoration: none;
			}
		}
		
		.event-details {
			margin-bottom: rem-calc(40);
			
			.event-detail {
				margin-top: rem-calc(10);
				display: flex;
				align-items: center;
				flex-flow: row wrap;
				
				&.description {
					display: block !important;
				}
			}
			
			.event-item-info {
				display: inline-flex;
				align-items: center;
			}
			.label-icon {
				display: inline-flex;
				padding-right: rem-calc(8);
				
				svg {
					max-height: rem-calc(16);
					width: auto;
					
					path {
						fill: $blue;
					}
				}
			}
			.detail-label {
				color: $blue;
				font-weight: 600;
				
				@include breakpoint(small only) {
					font-size: rem-calc(18);
				}
				
				@include breakpoint(medium) {
					font-size: rem-calc(20);
				}
			}
		}
	}
}

.filter-pagination {
	padding-top: rem-calc(50);
	
}

.pagination a, .pagination button {
	padding: $button-padding;
	color: $button-color;
}



/* Resources */
/*
.resources {
	.resource {
		.resource-wrapper {
			@include breakpoint(medium) {
				a {
					height: 100%;
					width: 100%;

					img {
						height: rem-calc(226.11);
						width: 100%;
						object-fit: cover;
					}
				}
			}
			@include breakpoint(medium only) {
				
			}
			@include breakpoint(large) {
				
			}
		}
	}
}
*/

/*********************
POSTS & CONTENT STYLES
*********************/
.page-title {
	
	// Remove Foundation vcard styling from author page titles
	.vcard {
		border: 0px;
		padding: 0px;
	}
}

// Post meta  
.byline {
	color: #999;
	time {}
}

// Entry content  
.entry-content {
	
	img {
		max-width: 100%;
		height: auto;
	}

	.alignleft, img.alignleft { 
		margin-right: 1.5em; 
		display: inline; 
		float: left; 
	}
	.alignright, img.alignright { 
		margin-left: 1.5em; 
		display: inline; 
		float: right; 
	}
	.aligncenter, img.aligncenter { 
		margin-right: auto; 
		margin-left: auto; 
		display: block; 
		clear: both; 
	}
			
	video, object {
		max-width: 100%;
		height: auto;
		}

	pre {
		background: #eee;
		border: 1px solid #cecece;
		padding: 10px;
		}

} 

.wp-caption {
	max-width: 100%;
	background: #eee;
	padding: 5px;

	// Images inside wp-caption  
	img {
		max-width: 100%;
		margin-bottom: 0;
		width: 100%;
	}

	p.wp-caption-text {
		font-size: 0.85em;
		margin: 4px 0 7px;
		text-align: center;
	}
} 

// Add Foundation styling to password protect button
.post-password-form input[type="submit"] {
	@include button;
}



/* 404 Template */
.content-not-found {
	.page-links {
		margin-bottom: rem-calc(40);
		a {
			color: $teal;
		}
	}
	
	.cta-404 {
		margin-top: rem-calc(100);
		padding-top: rem-calc(100);
		position: relative;
		
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			height: rem-calc(3);
			background: rgba($gray, .25);
			transform: translate(-50%, 50%);
			@include breakpoint(large) {
				width: rem-calc(330);
			}
	
		}
		
		.button {
			margin-top: rem-calc(24);
		}
	}
	
	
	.svg-wrapper {
		svg {
			display: block;
			max-width: 100%;
			height: auto;
			#right-eye, #left-eye, #frown-2 {
				visibility: hidden;
			}
		}
	}
}

/*********************
IMAGE GALLERY STYLES
*********************/
.gallery {
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-right: -15px;
	margin-left: -15px; 
}
.gallery::before, .gallery::after {
	content: ' ';
	display: table; 
}
.gallery::after {
	clear: both; 
}
.gallery.gallery-columns-1 > dl {
	@include grid-column;
}
.gallery.gallery-columns-2 > dl {
	@include grid-column(1 of 2);
}
.gallery.gallery-columns-3 > dl {
	@include grid-column(1 of 3);
}
.gallery.gallery-columns-4 > dl {
	@include grid-column(1 of 4);
}
.gallery.gallery-columns-5 > dl {
	@include grid-column(1 of 5);
}
.gallery.gallery-columns-6 > dl {
	@include grid-column(1 of 6);
}
.gallery.gallery-columns-7 > dl {
	@include grid-column(1 of 7);
}
.gallery.gallery-columns-8 > dl {
	@include grid-column(1 of 8);
}
.gallery dl {
	width: 100%;
	float: left;
	padding-left: 0.9375rem;
	padding-right: 0.9375rem; 
}
.gallery dl:last-child:not(:first-child) {
  float: right; 
 }

// Gallery caption styles
.gallery-caption {}
.size-full {}
.size-large {}
.size-medium {}
.size-thumbnail {}

/*********************
PAGE NAVI STYLES
*********************/
.page-navigation {
	margin-top: 1rem;
}

/*********************
COMMENT STYLES
*********************/
#comments {
	.commentlist {
		margin-left: 0px;
	}
}

#respond {
	ul {
		margin-left: 0px;
	}
}

.commentlist {
	li {
		position: relative;
		clear: both;
		overflow: hidden;
		list-style-type: none;
		margin-bottom: 1.5em;
		padding: 0.7335em 10px;

		&:last-child {
			margin-bottom: 0;
		}

		ul.children {
			margin: 0;

			li {}

			.comment {}
			.alt {}
			.odd {}
			.even {}
			.depth-1 {}           // Change number for different depth  
			.byuser {}
			.bypostauthor {}
			.comment-author-admin {}

		}
	}

	li[class*=depth-] {
		margin-top: 1.1em;
	}

	li.depth-1 {
		margin-left: 0;
		margin-top: 0;
	}

	li:not(.depth-1) {
		margin-left: 10px;
		margin-top: 0;
		padding-bottom: 0;
	}

	li.depth-2 {}
	li.depth-3 {
	}
	li.depth-4 {}
	li.depth-5 {}

	// General comment classes  
	.alt {}
	.odd {
	}
	.even {
	}
	.parent {}
	.comment {}
	.children {}
	.pingback {}
	.bypostauthor {}
	.comment-author {}
	.comment-author-admin {}
	.thread-alt {}
	.thread-odd {}
	.thread-even {}

	// vcard  
	.vcard {
		margin-left: 50px;

		cite.fn {
			font-weight: 700;
			font-style: normal;

			a.url {}

		}

		time {
			float: right;

			a {
				color: #999;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}

			}

		}

		img.photo {}

		img.avatar {
			position: absolute;
			left: 10px;
			padding: 2px;
			border: 1px solid #cecece;
			background: #fff;
		}

	} // end .commentlist .vcard  


	// comment meta  
	.comment-meta {

		a {}

	}

	.commentmetadata {

		a {}

	}

	// comment content  
	.comment_content {

		p {
			margin: 0.7335em 0 1.5em;
			font-size: 1em;
			line-height: 1.5em;
		}

	} // end .commentlist .comment_content  

	// comment reply link  
	.comment-reply-link {
		float: right;

		&:hover,
		&:focus {
		
		}

	} // end .commentlist .comment-reply-link  

} // end .commentlist  

/*********************
COMMENT FORM STYLES
*********************/
.respond-form {
	margin: 1.5em 10px;
	padding-bottom: 2.2em;

	form {
		margin: 0.75em 0;

		li {
			list-style-type: none;
			clear: both;
			margin-bottom: 0.7335em;

			label,
			small {
				display: none;
			}
		}

		input[type=text],
		input[type=email],
		input[type=url],
		textarea {
			padding: 3px 6px;
			background: #efefef;
			border: 2px solid #cecece;
			line-height: 1.5em;

			&:focus {
				background: #fff;
			}

			// form validation  
			&:invalid {
				outline: none;
				border-color: #fbc2c4;
				background-color: #f6e7eb;
				-webkit-box-shadow: none;
				   -moz-box-shadow: none;
				    -ms-box-shadow: none;
						box-shadow: none;
			}
		}

		input[type=text],
		input[type=email],
		input[type=url] {
			max-width: 400px;
			min-width: 250px;
		}

		textarea {
			resize: none;
			width: 97.3%;
			height: 150px;
		}

	}
}

// comment form title  
#comment-form-title {
	margin: 0 0 1.1em;
}

// cancel comment reply link  
#cancel-comment-reply {
	a {}
}

// logged in comments  
.comments-logged-in-as {}

// allowed tags  
#allowed_tags {
	margin: 1.5em 10px 0.7335em 0;
}

// No comments  
.nocomments {
	margin: 0 20px 1.1em;
}

/*********************
SIDEBARS & ASIDES
*********************/
.sidebar {
	li{
		list-style: none;
	}
}

.widget {
	ul {
		margin: 0;
	}
}
/*********************
FOOTER STYLES
*********************/
.footer:not(.site-footer) {
	clear: both;
	margin-top: 1em;
}

.inner-footer-v2 {
	@include breakpoint(medium down) {
		padding-top: rem-calc(60);
		padding-bottom: rem-calc(60);
	}
	@include breakpoint(large) {
		padding: {
			top: rem-calc(48);
			bottom: rem-calc(120);
		}
	}
	

	color: $dark-blue;

	.kiwi-info {
		@include breakpoint(medium down) {
			text-align: center;
		}
	}

	.social-media-links {
		@include breakpoint(medium down) {
			padding-top: 0 !important;
			.menu {
				justify-content: center;
			}
		}
	}

	.footer-partner-badge {
		@include breakpoint(medium down) {
			text-align: center;
		}
	}

	.kiwi-info-wrapper {
		@include breakpoint(medium only) {
			padding-bottom: rem-calc(60);
		}
	}

	.newsletter-signup {
		h2, h3 {
			color: $dark-blue !important;
			@include breakpoint(medium down) {
				text-align: center;
			}
		}
		form {
			@include breakpoint(medium) {
				display: flex;
				align-items: flex-end;
			}
			
			.gform-field-label {
				font-size: rem-calc(18);
				color: $dark-blue;
				font-weight: 600;
				text-transform: uppercase;

			}
			.gform-body {
				@include breakpoint(medium only) {
					flex: 1 1 0;
					-webkit-box-flex: 1;
					width: auto;
				}
			}
			.gform_footer {
				@include breakpoint(medium only) {
					flex: 0 0 auto;
					width: auto;
					-webkit-box-flex: 0;
					padding: 0 !important;
					margin: 0 !important;
				}
			}

			input[type="text"] {
				width: 100%;
				height: rem-calc(53) !important;
				border: 1px solid $blue !important;
				padding-left: rem-calc(18) !important;
				padding-right: rem-calc(18) !important;
				font-size: 1rem !important;
				margin-bottom: 0 !important;
				
			}
			input[type="submit"] {
				margin: 0 !important;
				border: 1px solid $dark-blue !important;
				padding: rem-calc(15) rem-calc(20);
				text-align: center;
				
				font-size: rem-calc(18) !important;
				background: $dark-blue;
				color: $white;
				text-transform: uppercase !important;

				@include breakpoint(small only) {
					display: block !important;
				}
				@include breakpoint(medium) {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					height: rem-calc(53);
				}
			}
		}
	}

	.footer-top-grid {
		@include breakpoint(medium down) {
			justify-content: center;
		}
	}

	.footer-nav-column {
		@include breakpoint(small only) {
			&:nth-child(n+3) {
				padding-top: rem-calc(50);
			}
		}
		ul.menu {
			> li {
				&:not(:last-child) {
					padding-bottom: rem-calc(20);
				}
				&:not(:first-child) {
					padding-top: rem-calc(20);
				}
				&.has-arrow {
					a {
						position: relative;
						padding-right: rem-calc(28) !important;

						&:after {
							content: '';
							position: absolute;
							top: 50%;
							right: 0;
							transform: translateY(-50%);
							width: rem-calc(19);
							height: rem-calc(16);
							background: url(../images/svg/arrow-right.svg) no-repeat center center;
							background-size: contain;
						}
					}
				}
				> a {
					font-size: rem-calc(20);
					color: $dark-blue;
					font-weight: 600;
				}
				
			}

			a {
				text-decoration: underline;
				padding: 0 !important;
				display: inline-block;
			}
		}
		ul.sub-menu {
			padding-top: rem-calc(20);
			list-style: none !important;
			margin-left: 0 !important;
			padding-left: 0 !important;
			li {
				&:not(:last-child) {
					padding-bottom: rem-calc(20);
				}
				a {
					font-size: rem-calc(16);
					color: $gray;
					
				}
			}
		}
	}



}
.footer-divider-wrapper {
	padding-top: rem-calc(50);
}
.footer-divider {
	height: 2px;
	width: 100%;
	background: $white;
}

.footer-menu-columns-grid {
	@include breakpoint(medium down) {
		justify-content: center;
	}
}

.footer-badges {
	@include breakpoint(medium down) {
		padding-top: rem-calc(60);
	}
}

.accreditation-badges {
	padding-top: rem-calc(36);
	@include breakpoint(medium down) {
		justify-content: center;
	}
}

.footer-botttom-grid {
	@include breakpoint(small only) {
		margin-right: rem-calc(-10) !important;
		margin-left: rem-calc(-10) !important;
	}
	@include breakpoint(medium) {
		margin-right: rem-calc(-15) !important;
		margin-left: rem-calc(-15) !important;
	}
}

.footer-info {
	padding-top: rem-calc(90);

	@include breakpoint(medium down) {
		text-align: center;
	}

	.copyright {
		font-size: rem-calc(12);
		color: $gray;
		margin-bottom: rem-calc(10) !important;
	}

	#footer-links {
		@include breakpoint(medium down) {
			justify-content: center;
		}
		li {
			line-height: 1 !important;
			&:not(:first-child) {
				padding-left: rem-calc(10);
				border-left: 1px solid $gray;
			}
			&:not(:last-child) {
				padding-right: rem-calc(10);
			}
		}
		a {
			padding: 0 !important;
			text-decoration: underline;
			font-size: rem-calc(12);
			color: $gray;
			line-height: 1 !important;
		}
	}

	.recaptcha-disclaimer {
		font-size: rem-calc(10);
		color: $gray;
		padding-top: rem-calc(30);
		padding-bottom: 0 !important;
		a {
			color: $gray;
			font-size: rem-calc(10);
		}
	}
}

.inner-footer {

	
	/*
	background: {
		image: url(../images/svg/graphic-homepage-footer-gradient.svg);
		size: cover;
		repeat: no-repeat;
	}
	*/

	
	color: $dark-blue;
	
	@include breakpoint(small only) {
		padding-top: rem-calc(120);
		padding-bottom: rem-calc(50);
		margin-top: 2rem;
		background-position: right top;
	}
	
	@include breakpoint(medium) {
		padding-top: rem-calc(240);
		padding-bottom: rem-calc(80);
		background-position: center top;
	}
	
	@include breakpoint(large) {
		padding-top: rem-calc(250);
	}
	

	.newsletter-signup {
		h2, h3 {
			color: $kiwi;
			margin: 0;
		}
		h2 {
			font-weight: 600;
		}
		h3 {
			font-weight: 400;
		}
		
		.gform_wrapper {
			margin-bottom: 0;
			.gform_body {
				.gfield {
					position: relative;
					[type=text] {
						width: 100%;
						border: {
							top: none;
							left: none;
							right: none;
							bottom: 1px solid $kiwi;
						}
						background: transparent;
						box-shadow: none;
						color: $white;
					}
					
					&.newsletter-email {
						padding-top: 1rem;
						label.gfield_label {
							text-transform: uppercase;
							position: absolute;
							left: 0;
							top: 0;
							color: $white;
							transition: all .3s ease-in-out;
							tranform: translateY(50%);
							font: {
								family: $form-font-family;
								weight: 500;
							}
							
							span {
								color: $white;
							}
							
							@include breakpoint(small only) {
								font-size: rem-calc(12);
							}
							
							@include breakpoint(medium) {
								font-size: rem-calc(16);
							}
							
						}
					}
					
					
				}
			}
			
			
			.gform_footer {
				.button {
					border: 1px solid $white;
					background: transparent;
					padding: rem-calc(15) rem-calc(20);
					color: $white;
					font: {
						size: rem-calc(18);
						weight: 700;
					}
					text-transform: uppercase;
				}
			}
		}
		
		@include breakpoint(small only) {
			margin-top: rem-calc(40);
			h2 {
				font-size: rem-calc(16);
			}
			h3 {
				font-size: rem-calc(14);
			}
		}
		
		@include breakpoint(medium only) {
			margin-bottom: rem-calc(50);
		}
		
		@include breakpoint(medium) {
			
			h2 {
				font-size: rem-calc(24);
			}
			h3 {
				//font-size: rem-calc(22);
				font-size: 1.2rem;
			}
		}
	}
}

.kiwi-info-wrapper {
	.kiwi-info {
		div {
			@include breakpoint(small only) {
				font-size: rem-calc(16);
			}
			@include breakpoint(medium) {
				//font-size: rem-calc(20);
				font-size: rem-calc(16);
			}
			
		}

		
	}
	.kiwi-logo {
		margin-bottom: rem-calc(30);
		svg {
			#Group_245, #Group_246 {
				path {
					fill: $dark-blue;
				}
			}
		}	
	}
	a {
		text-decoration: none;
		color: $white;
	}
	.kiwi-name {
		a {
			font-weight: 600;
			color: $kiwi;
			
		}
	}
	.kiwi-address {
		&, & > div {
			margin-bottom: rem-calc(5);
		}
	}
	.social-media-links {
		padding-top: rem-calc(32);
		
		.menu {
			li {
				&:not(:first-child) {
					padding-left: rem-calc(15);
				}
				a {
					padding: 0;

					&:hover {
						svg path {
							fill: $teal;
						}
					}
				}

				svg {
					path {
						fill: $blue;
						transition: all .2s ease-in-out;
					}
				}
			}
		}
	}
	
	@include breakpoint(small only) {
		margin-bottom: rem-calc(40);
	}
}

.footer-nav-wrapper {
	#menu-footer-nav {
		li {
			padding-bottom: rem-calc(10);
			padding-right: rem-calc(15);
			a {
				color: $white;
				text-decoration: underline;
				@include breakpoint(small only) {
					font-size: rem-calc(14);
				}
				@include breakpoint(medium) {
					//font-size: rem-calc(18);
					font-size: rem-calc(16);
				}
				
			}
		}
	}
}

.recaptcha-disclaimer {
	//margin-top: rem-calc(40);
	padding-bottom: rem-calc(30);
	color: $white;
	&, & a {
		font-family: $form-font-family;
		font-size: rem-calc(12);
	}
	a {
		text-decoration: underline;
		color: $kiwi;
		display: inline-block;
	}
	
}

.footer-top {
	padding-top: rem-calc(30);
	@include breakpoint(medium only) {
		padding-top: rem-calc(100);
		> .grid-x {
			.cell:nth-child(n+3) {
				margin-top: rem-calc(50);
			}
		}
	}
	.footer-nav-column {
		ul.menu {
			> li {
				&:not(:first-child) {
					padding-top: rem-calc(30);
				}
				> a {
					font-weight: 600;
					color: $kiwi;
					@include breakpoint(medium) {
						font-size: rem-calc(20);
					}
					@include breakpoint(small only) {
						font-size: rem-calc(16);
					}
				}
			}

			a {
				display: inline-block;
				padding: 0;
			}

			ul.sub-menu {
				margin-left: 0;
				list-style: none;
				li {
					padding-top: rem-calc(20);
					a {
						color: $white;
						text-decoration: underline;
						@include breakpoint(small only) {
							font-size: rem-calc(14);
						}
					}
				}
			}
		}

	}
}

.footer-signup {
	
	h2 {
		font-size: rem-calc(28);
		margin-bottom: rem-calc(5);
	}

	h3 {
		font-size: rem-calc(24);
		font-weight: 400 !important;
		color: $dark-blue;
	}
	form {
		@include breakpoint(large) {
			display: flex;
			flex-flow: row wrap;

			.gform_body {
				@include xy-cell-base(auto);
				width: auto;

				input {
					margin-bottom: 0 !important;
				}
			}
			.gform_footer {
				@include xy-cell-base(shrink);
				width: auto;
				padding-bottom: 0 !important;
			}
		}
		
	}

	.disclaimer {
		font-size: rem-calc(12);
	}
}

.footer-bottom {
	@include breakpoint(medium down) {
		margin-top: rem-calc(100);
	}
	@include breakpoint(large) {
		padding-top: rem-calc(80);
	}
	.copyright, #footer-links a {
		//color: $white;
		
		line-height: 1.2;
		font-family: $form-font-family;
		@include breakpoint(small only) {
			font-size: rem-calc(12);
		}
		@include breakpoint(medium) {
			//font-size: rem-calc(16);
			font-size: rem-calc(12);
		}
	}
	
	.copyright {
		margin-bottom: 0;
		padding-top: rem-calc(15);
		color: $white;
	}
	
	#footer-links {
		li {
			&:not(:first-child) {
				padding-left: rem-calc(8);
				border-left: 1px solid $kiwi;
			}
			&:not(:last-child) {
				padding-right: rem-calc(8);
			}
			a {
				padding: 0;
				text-decoration: underline;
				color: $kiwi;
				line-height: 1;
			}
		}
	}
}
	
/*********************
VISUAL EDITOR STYLES
*********************/
// Makes content look better in visual editor
body#tinymce {
   	margin: 20px;
}

/*********************
PLUGIN STYLES
*********************/
// Makes Gravity Forms play nice with Foundation Form styles
.block-wrapper {
	.gform-body {
		label.gfield_label {
			color: $blue;
			font: {
				weight: 500 !important;
				family: $form-font-family;
				size: rem-calc(14) !important;
			}
			text-transform: uppercase;
			line-height: 1 !important;
		}
		input, textarea {
			padding: {
				left: 1rem !important;
				right: 1rem !important;
			}
		}
		.ginput_container {
			margin-top: 0 !important;
		}
		select {
			padding-left: 1rem !important;
			background: {
				image: url('data:image/svg+xml;utf8,<svg id="Layer_1" enable-background="new 0 0 128 128" height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg"><path id="Down_Arrow_3_" d="m64 88c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l37.172 37.172 37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"/></svg>');
				position: center right 8px;
				size: 20px 20px;
				repeat: no-repeat;
			}
		}
	}
}
.gform_body ul {
    list-style: none outside none;
    margin: 0;
}

// Hide Gravity Forms validation fields when GFCSS is disabled
.gform_validation_container,
.gform_wrapper .gform_validation_container,
body .gform_wrapper li.gform_validation_container,
body .gform_wrapper .gform_body ul.gform_fields li.gfield.gform_validation_container,
body .gform_wrapper ul.gform_fields li.gfield.gform_validation_container {
    display: none !important;
    position: absolute !important;
    left: -9000px;
}

.gform_wrapper {
	ul.gfield_checkbox {
		display: flex;
		flex-flow: row wrap;
		margin-left: rem-calc(-15);
		margin-right: rem-calc(-15);
		padding-left: 0 !important;


		> li {
			display: block;
			margin-bottom: rem-calc(5) !important;
			margin-top: rem-calc(5) !important;
			padding-left: rem-calc(15);
			padding-right: rem-calc(15);
			width: 50%;

			input {
				margin-bottom: rem-calc(7) !important;
				margin-right: rem-calc(4) !important;
			}
			label {
				font-size: rem-calc(16) !important;
			}
		}
	}
}


/* Success Stories Template Styles */
.sub-page-banner .h1 {
	color: $white !important;
	padding-bottom: rem-calc(20);
	font-weight: 700;
	position: relative;
	margin: 0;
	line-height: 1.25;

	&:after {
		content: '';
		//width: var(--afterWidth);
		position: absolute;
		left: 0;
		bottom: 0;
		height: 0.25rem;
		background-color: #c1d831;
		transform: translateY(2px);
	}
}
.portfolio-intro {
	h1 {
		color: $blue;
		font-weight: 600 !important;
	}
	.intro-text {
		font-size: rem-calc(24);
		line-height: 1.3 !important;
	}
	.awards {
		padding-top: rem-calc(64);
		.award-image {
			padding-bottom: rem-calc(25);
			img {
				max-height: rem-calc(90);
				width: auto;
			} 
		}

		.award-text {
			font-size: rem-calc(18);
		}
	}
}

.services-heading {
	@include breakpoint(medium) {
		display: inline-block;
	}
	
	background: transparent linear-gradient(96deg, rgba(0, 112, 105, 1) 0%, rgba(20, 65, 88, 1) 100%) 0% 0% no-repeat padding-box;
	
	text-align: center;
	transform: translateY(-50%);
	
	@include breakpoint(small only) {
		padding: rem-calc(16) rem-calc(20);
	}
	
	@include breakpoint(medium) {
		padding: rem-calc(16) rem-calc(42);
	}
	
	h2 {
		color: $white;
		text-transform: uppercase;
		margin: 0;
		letter-spacing: rem-calc(1.88);
		
		@include breakpoint(small only) {
			font-size: rem-calc(18);
		}

		@include breakpoint(medium only) {
			font-size: rem-calc(21);
		}

		@include breakpoint(large) {
			font-size: rem-calc(25);
		}
	}
}

.project-details {
	@include breakpoint(small only) {
		padding-top: rem-calc(40);
	}
	@include breakpoint(medium) {
		padding-bottom: rem-calc(64);
	}
	.color-divider {
		@include breakpoint(small only) {
			padding-top: rem-calc(60);
			padding-bottom: rem-calc(60);
		}
		@include breakpoint(medium) {
			padding-top: rem-calc(120);
			padding-bottom: rem-calc(120);
		}
	}

	
}


.project-detail-section {

	ul, ol {
		li {
			&:not(:first-child) {
				padding-top: rem-calc(8);
			}
		}
	}

	.caption {
		font-size: rem-calc(15);
		font-weight: 700;
		color: $gray;
		padding-top: rem-calc(16);
	}
	@include breakpoint(small only) {
		.img-cell {
			padding-bottom: rem-calc(30);
		}
	}
	
	@include breakpoint(medium) {
		h2 {
			font-size: rem-calc(28) !important;
			color: $teal;
		}
		
	}
	
}

.project-services {
	margin-bottom: rem-calc(80);
	.services {
		padding-top: rem-calc(30);
		padding-bottom: rem-calc(90);
	}
	.slick-list {
		margin: 0 rem-calc(-15);
		padding: rem-calc(15) 0;
	}
	.slick-slide {
		padding: 0 rem-calc(15);
	}
	.project-service {
		background: $white;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
		height: 100%;
		padding: rem-calc(45) rem-calc(32);
	}
}



.cta-form-wrapper {
	form {
		label {
			color: $white !important;
		}
	}
}

.ss-cta-form-block {
	padding-top: rem-calc(150);
	padding-bottom: rem-calc(100);
}

.ss-aaa {
	position: relative;
	padding-top: rem-calc(120);
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		height: rem-calc(3);
		width: 30%;
		background: rgba($gray, .25);
		transform: translateX(-50%);
	}
}


/* Notification Bar */
.bg-blue {
	background: $blue;
}
.bg-navy {
	background: $dark-blue;
}
.notification-bar {
	padding-top: rem-calc(5);
	padding-bottom: rem-calc(5);
	font-size: 1rem;
	color: $white;

	/*
	.nb-grid {
		margin-left: rem-calc(-3);
		margin-right: rem-calc(-3);

		.cell {
			padding-left: rem-calc(3);
			padding-right: rem-calc(3);
		}
	}
	*/
	p {
		margin-bottom: 0;
	}

	.button {
		border-width: rem-calc(2) !important;
		font-size: rem-calc(14) !important;
		padding-top: rem-calc(8);
		padding-bottom: rem-calc(8);

		&.solid-kiwi {
			border: rem-calc(2) solid $kiwi !important;

			&:hover {
				border-color: $white !important;
			}
		}
	}

}

.bg-dark-blue {
	background-color: $dark-blue;
}

/* Updated banner Styles */

.sub-page-banner {

	.sub-page-banner-inner {
		&.has-nb {
			@include breakpoint(small only) {
				padding-top: 15rem;
				padding-bottom: rem-calc(50);
			}
			
			@include breakpoint(medium only) {
				padding-top: rem-calc(220);
				padding-bottom: rem-calc(100);
			}
			
			@include breakpoint(large) {
				//padding-top: rem-calc(206);
				//padding-bottom: rem-calc(160);
				padding-top: 15rem;
				padding-bottom: 9rem;
			}
		}
		&:not(.has-nb) {
			@include breakpoint(small only) {
				padding-top: rem-calc(120);
				padding-bottom: rem-calc(50);
			}
			
			@include breakpoint(medium only) {
				padding-top: rem-calc(170);
				padding-bottom: rem-calc(90);
			}
			
			@include breakpoint(large) {
				//padding-top: rem-calc(206);
				//padding-bottom: rem-calc(160);
				padding-top: 12.5rem;
				padding-bottom: 7rem;
			}
		}
	}
	
}

/**********************
Contact Page Template
**********************/
.body-h1 {
	
	font-weight: 600;
	color: $blue;

	@include breakpoint(medium) {
		font-size: rem-calc(46);
	}

	&.has-underline {
		position: relative;
		padding-bottom: rem-calc(24);
		margin-bottom: rem-calc(40) !important;

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			height: rem-calc(3);
			width: 50%;
			background: $kiwi;
		}
	}
}

.blue {
	color: $blue;
}

.page-template-template-contact {
	.static-breadcrumbs {
		margin-bottom: rem-calc(60);
	}
	@include breakpoint(medium) {
		.body-copy-wrapper {
			padding-right: 12%;
		}
	}
	.body-copy {
		*, p, span, a, li {
			font-size: rem-calc(17.6);
		}

		ol, ul {
			li:not(:first-child) {
				padding-top: rem-calc(8);
			}
		}
	}
	.color-divider {
		padding-top: rem-calc(45);
		padding-bottom: rem-calc(60);
	}
}

.contact-form-wrapper {
	padding: rem-calc(55);

	@include breakpoint(medium down) {
		margin-bottom: rem-calc(64);
	}

	h2 {
		font-size: rem-calc(32) !important;
	}

	input {
		
		&[type=text], &[type=email], &[type=tel] {
			height: rem-calc(53);
			border: none!important;
			padding: 1rem .5rem !important;
			width: 100%;
		}

		&[type=checkbox] {
			border: none !important;
			//-webkit-appearance: none;
			height: 1rem;
			width: 1rem;
			background: $white;
		}

		&[type=submit] {
			background: $blue;
			border-color: $blue;
			color: $white;

			&:hover {
				background: transparent;
				color: $blue !important;
			}
		}
	}

	select {
		height: rem-calc(53);
		border: none!important;
		padding: .5rem 1rem !important;
		width: 100%;
		background: {
			image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.988' height='7.677' viewBox='0 0 11.988 7.677'%3E%3Cpath id='Path_4828' data-name='Path 4828' d='M-10769-16823.742l5.266,4.727-5.266,4.441' transform='translate(-16813.166 10770.414) rotate(90)' fill='none' stroke='%23222d3a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E") !important;
			repeat: no-repeat;
			size: 12px 7px;
			position: right center;

		}
	}
	textarea {
		border: none !important;
		
	}

	.gfield--type-html {
		font-size: rem-calc(11) !important; 
		margin-top: rem-calc(20) !important;
	}

	.gchoice label.gform-field-label {
		font-size: rem-calc(12) !important;
		color: $dark-blue !important;
		font-weight: 600 !important;
	}

	div.ginput_container {
		margin-top: 5px !important;
	}

	.gfield_label {
		margin-bottom: 0 !important;
		color: $dark-blue !important;
		font-weight: 600 !important;
	}

	.gform_fields {
		.gfield {
			padding-right: 0 !important;

		}
	}

	
}

.contact-calendar-wrapper {
	h2 {
		font-size: rem-calc(28) !important;
		color: $teal !important;
	}

	.compact-container {
		max-width: 100% !important;
	}
}
